<template>
  <div class="row">
    <div class="col">
      <AdsBanner></AdsBanner>
    </div>
  </div>

  <div class="container">
    <div class="row overflow-hidden">
      <div class="col">
        <div class="card mt-1 mx-auto lgtxt my-container">
          <div class="card-header text-center">
            <i class="bi bi-info-circle float-start" @click="toInfo()"></i>
            Scorecard for
            {{ store.mastername }}
          </div>
          <div class="card-body">
            <table class="table table-bordered rounded">
              <tr v-if="win_rank">
                <td class="text-end" style="width: 50%">Ranking</td>
                <td class="text-center" style="width: 50%">
                  Wins: {{ win_rank }}, SR: {{ sr_rank }}
                </td>
              </tr>
              <tr>
                <td class="text-end" style="width: 50%">Total Games</td>
                <td class="text-center" style="width: 50%">
                  {{ ss.subevents.length }}
                </td>
              </tr>
              <tr>
                <td class="text-end" style="width: 50%">Results</td>
                <td class="text-center" style="width: 50%">
                  {{ totalResultsDeclared() }}
                </td>
              </tr>
              <tr class="text-primary fw-bold">
                <td class="text-end" style="width: 50%">My games</td>
                <td class="text-center" style="width: 50%">
                  {{ bs.submittedbids.length }}
                </td>
              </tr>
              <tr class="text-primary fw-bold">
                <td class="text-end" style="width: 50%">My results</td>
                <td class="text-center" style="width: 50%">
                  {{ resultsDeclaredForPlayedGames() }}
                </td>
              </tr>
              <tr class="text-primary fw-bold">
                <td class="text-end" style="width: 50%">Wins</td>
                <td class="text-center" style="width: 50%">
                  {{ correctBidsForThisUser() }}
                </td>
              </tr>
              <tr class="text-primary fw-bold">
                <td class="text-end" style="width: 50%">StrikeRate</td>
                <td class="text-center" style="width: 50%">
                  <span v-if="strikeRate() != NaN">{{ strikeRate() }}%</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useBids } from "../../store/bids";
import { useSubevents } from "../../store/subevents";
import { useStore } from "../../store/index";
import { db, auth } from "../../firebase/config";
import { doc, getDoc } from "firebase/firestore";
import AdsBanner from "../ads/AdsBanner.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const bs = useBids();
const ss = useSubevents();
const store = useStore();
const leaderboard = ref([]);
const userid = ref("");
const win_rank = ref(0);
const sr_rank = ref(0);
const toInfo = () => {
  router.push("/info");
};
onMounted(async () => {
  userid.value = getFirst8Chars(auth.currentUser.uid);
  const docRef = doc(db, "leaderboards", store.masterevent);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    leaderboard.value = docSnap.data().leaderboard;
    fetchWinsRank();
    fetchSRRank();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
});
function getFirst8Chars(str) {
  return str.substring(0, 8);
}
const totalResultsDeclared = () => {
  var count = 0;
  ss.subevents.forEach((s) => {
    if (s.result != null) {
      count++;
    }
  });
  return count;
};
const checkBid = (bid, eventid) => {
  var correct = false;
  ss.subevents.forEach((s) => {
    if (s.id == eventid) {
      if (s.result) {
        if (bid.bidid == s.result.bidid && bid.party == s.result.party) {
          correct = true;
        }
      }
    }
  });
  return correct;
};
const correctBidsForThisUser = () => {
  var correct = 0;
  bs.submittedbids.forEach((sb) => {
    if (checkBid(sb, sb.subevent)) {
      correct++;
    }
  });
  return correct;
};
const resultsDeclaredForPlayedGames = () => {
  var count = 0;
  bs.submittedbids.forEach((b) => {
    ss.subevents.forEach((s) => {
      if (b.subevent == s.id) {
        if (s.result) {
          count++;
        }
      }
    });
  });
  return count;
};
const strikeRate = () => {
  var rate = 0;
  rate = (
    (correctBidsForThisUser() / resultsDeclaredForPlayedGames()) *
    100
  ).toFixed(2);
  if (isNaN(rate)) {
    rate = 0;
  }
  return rate;
};
const fetchWinsRank = () => {
  var temp = [];
  var rank = 0;
  setTimeout(() => {
    temp = leaderboard.value.sort((a, b) => b.w - a.w);
    rank = temp.findIndex((obj) => obj.u == userid.value);
    win_rank.value = rank + 1;
  }, 2000);

  return;
};
const fetchSRRank = () => {
  var temp = [];
  var rank = 0;
  setTimeout(() => {
    temp = leaderboard.value.sort((a, b) => b.s - a.s);
    rank = temp.findIndex((obj) => obj.u == userid.value);
    sr_rank.value = rank + 1;
  }, 2000);
  return;
};
</script>
<style scoped>
.tinytxt {
  font-size: 8px;
}
.midtxt {
  font-size: 12px;
}
.lgtxt {
  font-size: 14px;
}
.my-container {
  background: linear-gradient(to bottom, #fcfcfc, #fafafa);
}
</style>
