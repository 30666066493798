<template>
  <!-- <ul class="list-group">
    <li class="list-group-item" v-for="p in sorted_leaders" :key="p.u">
      {{ displayName(p.u) }}
    </li>
  </ul> -->
  <table class="table table-bordered rounded text-muted">
    <tr v-for="p in sorted_leaders" :key="p.u">
      <td class="text-start">{{ displayName(p.u) }}</td>
      <td class="text-center">{{ p.w }}</td>
    </tr>
  </table>
</template>
<script setup>
import { ref, defineProps, onMounted, computed } from "vue";
const props = defineProps(["leaders", "displaynames"]);
const leaders = ref([]);
onMounted(() => {
  if (props.leaders) leaders.value = props.leaders;
});
const sorted_leaders = computed(() => {
  var arr = leaders.value;
  var sorted_arr = sortByField_ascending(arr, "sno");
  return sorted_arr;
});
function sortByField_ascending(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const displayName = (id) => {
  var name = "";
  if (props.displaynames[id]) {
    name = truncateString(props.displaynames[id]);
  }
  return name;
};
function truncateString(str) {
  if (str.length > 25) {
    return str.slice(0, 22) + "...";
  }
  return str;
}
</script>
