import { defineStore } from "pinia";

export const useSubevents = defineStore("subevents", {
  state: () => ({
    subevents: [], //this is a live listener - this array will always be update with live db data
    subevent: {},
    bidfor: "",
    bidid: "",
    trash: false,
  }),

  actions: {
    setSubevents(arr) {
      this.subevents = arr;
    },
    clearSubevents() {
      this.subevents = [];
    },
    setSubevent(obj) {
      this.subevent = { ...obj };
      this.bidfor = "";
      this.bidid = "";
    },
    clearSubevent() {
      this.subevent = {};
      this.bidfor = "";
      this.bidid = "";
    },
    bidFor(party) {
      if (this.bidfor != party) {
        this.bidfor = party;
      } else {
        this.bidfor = "";
      }
    },
    bidID(id) {
      if (this.bidid != id) {
        this.bidid = id;
      } else {
        this.bidid = "";
      }
    },
    clearBidSelection() {
      this.bidfor = "";
      this.bidid = "";
    },
    toggleTrash() {
      this.trash = !this.trash;
    },
    clearTrash() {
      this.trash = false;
    },
  },
});
