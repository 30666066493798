<template>
  <FirebaseMessages></FirebaseMessages>
  <div class="row">
    <div class="col">
      <AdsBanner></AdsBanner>
    </div>
  </div>
  <MastereventList v-if="!store.masterevent"></MastereventList>
  <!-- single handed operation version -->
  <MasterContainer v-if="store.masterevent"></MasterContainer>
  <!-- Original format -->
  <!-- <MasterHome v-if="store.masterevent"></MasterHome> -->
</template>
<script setup>
import MastereventList from "../../components/user/MastereventList.vue";
import AdsBanner from "../../components/ads/AdsBanner.vue";
import MasterHome from "./../user/MasterHome.vue";
import MasterContainer from "../userv2/MasterContainer.vue";
import FirebaseMessages from "../user/FirebaseMessages.vue";
import { db, auth } from "../../firebase/config";
import {
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { onMounted, onBeforeUnmount } from "vue";
import { useStore } from "../../store/index";
import { useBids } from "../../store/bids";
const store = useStore();
const bids = useBids();
var unsub = () => {};
onMounted(async () => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  //if user records does not exist insert new record
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    setTimeStamp();
    unsub = onSnapshot(doc(db, "users", auth.currentUser.uid), (docy) => {
      if (docy.data().timenow) bids.setPresentTime(docy.data().timenow.seconds);
    });
  } else {
    // docSnap.data() will be undefined in this case
    await setDoc(doc(db, "users", auth.currentUser.uid), {
      isadmin: false,
      timenow: serverTimestamp(),
      buildhash: "",
    });
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      ///console.log("Document data:", docSnap.data());
      if (docSnap.data().timenow) {
        //console.log("error here");
        bids.setPresentTime(docSnap.data().timenow.seconds);
      }
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    setTimeStamp();
  }
  //if it exists setup listner and store timestamp in store
});
const setTimeStamp = async () => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(docRef, {
    timenow: serverTimestamp(),
  });
};
onBeforeUnmount(() => {
  unsub();
});
</script>
<style>
.background {
  background-color: #f5f5f5;
}
</style>
