import { createRouter, createWebHistory } from "vue-router";
import { auth } from "../firebase/config";
import AdminLogin from "../views/Login/AdminLogin.vue";
import UserLogin from "../views/Login/UserLogin.vue";
import LogoutPage from "../views/Login/LogoutPage.vue";
import HomeView from "../views/Home/HomeView.vue";
import MyBids from "../views/Summary/MyBids.vue";
import MyLeaderboard from "../views/Leaderboard/MyLeaderboard.vue";
import AdminSettings from "@/views/Settings/AdminSettings";
import NewEvent from "@/components/events/NewEvent.vue";
import SetResults from "@/views/Results/SetResults.vue";
import TeamsHome from "@/components/teams/TeamsHome.vue";
import OneVThreeHome from "@/components/onevthree/OneVThreeHome.vue";
import NotifyHome from "@/components/notify/NotifyHome.vue";
import PrizesHome from "@/components/prizes/PrizesHome.vue";
import InfoPage from "@/components/info/InfoPage.vue";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  //console.log("checking requireAuth => ", user);
  if (!user) {
    next({ name: "login" });
  } else {
    next();
  }
};
const routes = [
  {
    path: "/",
    name: "login",
    component: UserLogin,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutPage,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminLogin,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    beforeEnter: requireAuth,
  },
  {
    path: "/event",
    name: "event",
    component: NewEvent,
    beforeEnter: requireAuth,
  },
  {
    path: "/settings",
    name: "settings",
    component: AdminSettings,
    beforeEnter: requireAuth,
  },
  {
    path: "/setresults",
    name: "setresults",
    component: SetResults,
    beforeEnter: requireAuth,
  },
  {
    path: "/mybids",
    name: "mybids",
    component: MyBids,
    beforeEnter: requireAuth,
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: MyLeaderboard,
    beforeEnter: requireAuth,
  },
  {
    path: "/teams",
    name: "teams",
    component: TeamsHome,
    beforeEnter: requireAuth,
  },
  {
    path: "/messages",
    name: "messages",
    component: NotifyHome,
    beforeEnter: requireAuth,
  },
  {
    path: "/prizes",
    name: "prizes",
    component: PrizesHome,
    beforeEnter: requireAuth,
  },
  {
    path: "/info",
    name: "info",
    component: InfoPage,
    beforeEnter: requireAuth,
  },
  {
    path: "/1v3",
    name: "1v3",
    component: OneVThreeHome,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
