import { defineStore } from "pinia";

export const useBids = defineStore("bids", {
  state: () => ({
    subeventbidspairs: [], //this is done to get the id of bid collection for submitting bid
    submittedbids: [], //bids submitted by this user
    allusersbids: [], //bids submitted by ALL users
    timenow: "",
  }),

  actions: {
    submittedBid(obj) {
      this.submittedbids.push(obj);
    },
    clearBids() {
      this.subeventbidspairs = [];
      this.submittedbids = [];
      this.allusersbids = [];
    },
    removeBid(id, userid) {
      //remove obj from bids[] that has subevent = id, incase bid is deleted by user
      var temp = this.submittedbids.filter((bid) => bid.subevent !== id);
      var temp_all = this.allusersbids.filter((bid) => bid.userid !== userid);

      this.submittedbids = temp;
      this.allusersbids = temp_all;
    },
    addPair(obj) {
      this.subeventbidspairs.push(obj);
    },
    addToAllBids(obj) {
      this.allusersbids.push(obj);
    },
    setPresentTime(t) {
      this.timenow = t;
    },
    clearPresentTime() {
      this.timenow = "";
    },
  },
});
