<template>
  <div class="container">
    <AdminNav v-if="store.isadmin"></AdminNav>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            Odds Options:
            <input
              type="text"
              class="form-control"
              v-model.trim="option"
              @keyup.enter="addOdd"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" v-for="o in sorted_odds" :key="o">
            <div class="row">
              <div class="col">
                <i
                  class="bi bi-dash"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'minus')"
                ></i>
                {{ o.sno }}
                <i
                  class="bi bi-plus"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'plus')"
                ></i>
              </div>
              <div class="col">
                <span @click="selectThis(o.id)">{{ o.name }}</span>
                <span
                  class="badge rounded-pill bg-primary float-end"
                  v-if="isNeutral(o.id)"
                  >Neutral</span
                >
                <span
                  class="badge rounded-pill bg-danger float-start"
                  v-if="isNeutral(o.id) && selectedOption == o.id"
                  @click="clearNeutral(o.id)"
                  >ClearNeutral</span
                >
              </div>
              <div class="col">
                <div class="d-flex justify-content-around">
                  <i
                    class="fa fa-arrows-h mx-2"
                    v-if="selectedOption == o.id"
                    @click="makeNeutralOption(o.id)"
                  ></i>
                  <i
                    class="bi bi-trash float-end"
                    v-if="selectedOption == o.id"
                    @click="deleteOption(o.id)"
                  ></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col">
        <input name="query" v-model="searchQuery" />
        <!-- {{ displaynames }} -->
        <!-- <UserGrid
          :data="gridData"
          :columns="gridColumns"
          :filter-key="searchQuery"
        >
        </UserGrid> -->
      </div>
    </div>
  </div>
</template>
<script setup>
import AdminNav from "../../components/navbars/AdminNav.vue";
import UserGrid from "./GridComponent.vue";
import { useStore } from "../../store/index";
import {
  doc,
  updateDoc,
  onSnapshot,
  deleteField,
  arrayUnion,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const userData = ref({});
const selectedOption = ref("");
const displaynames = ref({});
const searchQuery = ref("");
const gridColumns = ["name", "power"];
const gridData = [
  { name: "Chuck Norris", power: Infinity, id: nanoid },
  { name: "Bruce Lee", power: 9000, id: nanoid() },
  { name: "Jackie Chan", power: 7000, id: nanoid() },
  { name: "Jet Li", power: 8000, id: nanoid() },
];
const updateSno = async (id, sno, action) => {
  const washingtonRef = doc(db, "users", store.userid);
  var new_sno = parseInt(sno);
  if (action == "plus") {
    new_sno++;
  }
  if (action == "minus") {
    new_sno--;
  }
  await updateDoc(washingtonRef, {
    ["oddsoptions." + id + ".sno"]: new_sno,
    //fourballs: arrayUnion(fourballObj),
  });
  selectThis(id); //to clear out selection
};
const deleteOption = async (id) => {
  const cityRef = doc(db, "users", store.userid);

  // Remove the 'capital' field from the document
  await updateDoc(cityRef, {
    ["oddsoptions." + id]: deleteField(),
  });
};
const selectThis = (id) => {
  if (selectedOption.value != id) {
    selectedOption.value = id;
  } else {
    selectedOption.value = "";
  }
};
const sorted_odds = computed(() => {
  var arr = [];
  for (const property in userData.value.oddsoptions) {
    arr.push({
      id: property,
      ...userData.value.oddsoptions[property],
    });
  }
  let sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
var unsub = () => {};
onMounted(() => {
  unsub = onSnapshot(doc(db, "users", store.userid), (docy) => {
    userData.value = { id: docy.id, ...docy.data() };
  });
  getDisplayNames();
});
onBeforeUnmount(() => {
  console.log("unsub...");
  unsub();
});
const store = useStore();
const option = ref("");
const getLargestSno = () => {
  return Object.keys(userData.value.oddsoptions).length + 1;
};
const addOdd = async () => {
  const id = nanoid();
  const washingtonRef = doc(db, "users", store.userid);
  const oddObj = {
    name: option.value,
    sno: getLargestSno(),
  };
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    ["oddsoptions." + id]: oddObj,
    //fourballs: arrayUnion(fourballObj),
  });
  option.value = "";
  //console.log(option.value, store.userid, nanoid());
};
const isNeutral = (id) => {
  if (userData.value.neutraloptions) {
    if (userData.value.neutraloptions.includes(id)) return true;
  }
  return false;
};
const makeNeutralOption = async (id) => {
  const washingtonRef = doc(db, "users", store.userid);
  await updateDoc(washingtonRef, {
    neutraloptions: arrayUnion(id),
  });
  selectThis(id); //to clear out the selection option
};
const clearNeutral = async (id) => {
  const washingtonRef = doc(db, "users", store.userid);
  await updateDoc(washingtonRef, {
    neutraloptions: arrayRemove(id),
  });
  selectThis(id);
};
const getDisplayNames = async () => {
  const docRef = doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    displaynames.value = { ...docSnap.data().names };
    populateGrid();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
const populateGrid = () => {
  return;
  // for (const property in displaynames.value) {
  //   console.log(`${property}: ${object[property]}`);
  // }
};
</script>
