<template>
  <div class="container">
    <div class="card mx-auto mt-5 rounded-lg" style="width: 20rem">
      <div class="card-body my-container">
        <h3 class="card-title text-center">
          <!-- Senohe! <i class="bi bi-person float-end" @click="toAdmin()"></i> -->
          Senohe!
        </h3>
        <h6 class="card-title text-center text-muted mt-2">
          Predict results, win prizes.
        </h6>
        <div class="card-text">
          <h2 v-if="user">Signed In User: {{ user }}</h2>
          <div id="firebaseui-auth-container"></div>
          <div id="loader">Loading...</div>
          <br />
          <div v-if="isSignedIn">
            <button @click="handleSignOut">Sign Out</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-absolute bottom-0 start-50 translate-middle-x mb-2 smalltxt"
    >
      v<span class="text-muted smalltxt">{{ version }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from "vue";
import firebaseConfig from "../../firebase/firebaseConfig";
import { db } from "../../firebase/config";
import { version } from "@/version";
// v9 compat packages are API compatible with v8 code
import firebase from "firebase/compat/app";

firebase.initializeApp(firebaseConfig);
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useRouter } from "vue-router";
import { getAuth, signOut } from "firebase/auth";
const router = useRouter();
const auth = getAuth();
let ui;
//import { auth } from "../../firebase/config";
onMounted(() => {
  if (!firebaseui.auth.AuthUI.getInstance()) {
    ui = new firebaseui.auth.AuthUI(firebase.auth());
  } else {
    ui = firebaseui.auth.AuthUI.getInstance();
  }
  ui.start("#firebaseui-auth-container", uiConfig);
});

onBeforeUnmount(() => {
  if (ui) {
    ui.delete();
  }
});

const user = ref(null);
const isSignedIn = ref(false);

const uiConfig = {
  signInFlow: "popup",
  //signinSuccessUrl: "http://localhost:8080/",
  //signinSuccessUrl: "/home",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //firebase.auth.GithubAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: function (authResult) {
      user.value = authResult.user.displayName;
      console.log(authResult);
      isSignedIn.value = true;
      console.log("Signed in by user " + user.value, authResult.user.uid);
      // so it doesn't refresh the page
      setDisplayName(user.value, authResult.user.uid);
      router.push("/home");
      return false;
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById("loader").style.display = "none";
    },
  },
};

// Initialize the FirebaseUI Widget using Firebase.
// if (!firebaseui.auth.AuthUI.getInstance())
//   var ui = new firebaseui.auth.AuthUI(firebase.auth());

// ui.start("#firebaseui-auth-container", uiConfig);

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      user.value = null;
      isSignedIn.value = false;
      console.log("Signed out");
      ui.start("#firebaseui-auth-container", uiConfig);
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
};
const toAdmin = () => {
  router.push("/admin");
};
function getFirst8Chars(str) {
  return str.substring(0, 8);
}
const setDisplayName = async (name, uid) => {
  uid = getFirst8Chars(uid);
  console.log("Setting display Name=>", name, uid);
  const docRef = doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    if (!docSnap.data().names[uid]) {
      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        ["names." + uid]: name,
      });
    }
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container {
  height: 100vh;
}
.smalltxt {
  font-size: 12px;
}
.my-container {
  background: linear-gradient(to bottom, #fcfcfc, #fafafa);
}
</style>
