// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB21ngJEkybL5rthTdxRaXTLwrBiLIVVf4",
  authDomain: "odds-7948d.firebaseapp.com",
  projectId: "odds-7948d",
  storageBucket: "odds-7948d.appspot.com",
  messagingSenderId: "343468046896",
  appId: "1:343468046896:web:c320ff73984f5612738bf5",
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
const messaging = getMessaging();

export { db, auth, storage, messaging };
