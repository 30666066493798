<template>
  <div class="container mt-3 mb-3">
    <div class="row rounded">
      <div class="col bg-danger rounded" v-if="!anySubevents">
        <h6>Delete all subevents of this event first</h6>
      </div>
      <div class="col bg-danger rounded" v-if="anySubevents">
        <h6>This will delete the event and submitted bids. Cannot be undone</h6>
      </div>
      <div class="col">
        <button class="btn btn-sm btn-danger float-start" v-if="anySubevents">
          I'm sure. Delete this.
          <i class="bi bi-trash float-end" @click="imsuredeletethis()"></i>
        </button>
        <button class="btn btn-sm btn-success float-end" @click="goBack()">
          Go Back
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, computed } from "vue";
import { useSubevents } from "../../store/subevents";
import {
  doc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const emit = defineEmits(["deleteaction"]);
const se = useSubevents();
const props = defineProps(["subevent"]);
const anySubevents = computed(() => {
  var showdelete = true;
  se.subevents.forEach((s) => {
    if (s.parentevent == props.subevent) showdelete = false;
  });
  return showdelete;
});
const goBack = () => {
  emit("deleteaction", true);
};
const imsuredeletethis = async () => {
  //console.log(props.subevent);
  //await deleteDoc(doc(db, "subevents", props.subevent));
  const q = query(
    collection(db, "bids"),
    where("subevent", "==", props.subevent)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docy) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    await deleteDoc(doc(db, "bids", docy.id));
  });
  await deleteDoc(doc(db, "subevents", props.subevent));
};
</script>
