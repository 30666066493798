<template>
  <ul class="list-group">
    <li class="list-group-item bg-info text-center midtxt lesspadding">
      Declared Results
    </li>
    <li
      class="list-group-item midtxt lesspadding"
      v-for="p in sorted_prizes"
      :key="p.id"
    >
      <div class="row">
        <div class="col">
          {{ p.sno }}
          <i
            class="bi bi-trash float-end"
            v-if="todelete == p.id"
            @click="deletePrize(p.id)"
          ></i>
        </div>
        <div
          class="col border rounded"
          @click="selectToDelete(p.id, p.narration)"
        >
          <span v-if="p.user != 'SpecialPrize'">{{ displayName(p.user) }}</span>
          <span v-if="p.user == 'SpecialPrize'">{{ p.user }}</span>
        </div>
        <div class="col">
          <span v-if="todelete != p.id">{{ p.narration }}</span>

          <input
            type="text"
            class="form-control"
            v-if="todelete == p.id"
            v-model.trim="update_narration"
            @keyup.enter="updateNarration()"
          />
        </div>
        <div class="col">
          <i
            class="bi bi-chevron-down float-end"
            @click="selectPrize(p.id)"
          ></i>
        </div>
      </div>
      <div class="row" v-if="selectedprize == p.id">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="l in sorted_prize_leaders(p.leaders)"
            :key="l.sno"
          >
            <i
              class="bi bi-arrow-up-circle text-success"
              @click="shiftPosition('reduce', l)"
            ></i>
            {{ l.sno }}
            <i
              class="bi bi-arrow-down-circle text-danger"
              @click="shiftPosition('increase', l)"
            ></i
            >- {{ displayName(l.u) }}: {{ l.w }}

            <i class="bi bi-trash float-end" @click="deleteEntry(l)"></i>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>
<script setup>
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
  deleteField,
  arrayRemove,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { onMounted, onBeforeUnmount, ref, computed, defineProps } from "vue";
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
const store = useStore();
var unsubscribe = () => {};
var unsubnames = () => {};
const props = defineProps(["leaders"]);
const prizes = ref([]);
const displaynames = ref({});
const selectedprize = ref("");
const todelete = ref("");
const update_narration = ref("");
const sorted_prize_leaders = (arr) => {
  var ar = sortByField_ascending(arr, "sno");
  return ar;
};
function sortByField_ascending(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const shiftPosition = async (action, obj) => {
  console.log("Old value: ", obj.sno);
  const cityRef = doc(db, "masterevents", store.masterevent);

  await updateDoc(cityRef, {
    ["prizes." + selectedprize.value + ".leaders"]: arrayRemove(obj),
  });
  var newObj = { ...obj };
  if (action == "reduce") {
    newObj.sno--;
  }
  if (action == "increase") {
    newObj.sno++;
  }
  console.log("New Value: ", newObj.sno);
  await updateDoc(cityRef, {
    ["prizes." + selectedprize.value + ".leaders"]: arrayUnion(newObj),
  });
};
const deleteEntry = async (obj) => {
  console.log("Delete this=>", obj);
  const cityRef = doc(db, "masterevents", store.masterevent);

  await updateDoc(cityRef, {
    ["prizes." + selectedprize.value + ".leaders"]: arrayRemove(obj),
  });
};
const selectPrize = (id) => {
  if (selectedprize.value != id) {
    selectedprize.value = id;
  } else {
    selectedprize.value = "";
  }
};
const selectToDelete = (user, narration) => {
  if (todelete.value != user) {
    todelete.value = user;
  } else {
    todelete.value = "";
  }
  update_narration.value = narration;
};
onMounted(() => {
  getDisplayNames();
});
onBeforeUnmount(() => {
  unsubnames();
});
const displayName = (id) => {
  var name = "";
  if (displaynames.value[id]) {
    name = truncateString(displaynames.value[id]);
  }
  return name;
};
function truncateString(str) {
  if (str.length > 20) {
    return str.slice(0, 17) + "...";
  }
  return str;
}
const getDisplayNames = async () => {
  unsubnames = onSnapshot(
    doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e"),
    (docy) => {
      //console.log("Current data: ", doc.data());
      displaynames.value = { ...docy.data().names };
    }
  );
};

const sorted_prizes = computed(() => {
  const arr = [];

  for (const property in store.masterdata.prizes) {
    arr.push({ id: property, ...store.masterdata.prizes[property] });
  }
  var sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const deletePrize = async (id) => {
  //await deleteDoc(doc(db, "prizes", id));
  const cityRef = doc(db, "masterevents", store.masterevent);

  await updateDoc(cityRef, {
    ["prizes." + id]: deleteField(),
  });
};
const updateNarration = async () => {
  const washingtonRef = doc(db, "prizes", todelete.value);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    narration: update_narration.value,
  });
  todelete.value = "";
  update_narration.value = "";
};
</script>
<style scoped>
.midtxt {
  font-size: 12px;
}
.lesspadding {
  padding: 3px;
}
</style>
