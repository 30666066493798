<template>
  <div class="container border rounded border-primary">
    <div class="row border rounded bg-info">
      <div class="col">Editing SubEvent</div>
      <div class="col d-flex justify-content-around">
        <div class="float-start">
          <i
            class="bi bi-caret-down"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton1"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              class="dropdown-item"
              v-for="t in sorted_icons"
              :key="t.name"
              @click="updateIcon(t.icon, 'party_1')"
            >
              {{ t.team }}
            </li>
          </ul>
        </div>
        <TeamIcon :icon="props.subevent.logo_1"></TeamIcon>
      </div>
      <div class="col d-flex justify-content-around">
        <div class="float-start">
          <i
            class="bi bi-caret-down"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton1"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              class="dropdown-item"
              v-for="t in sorted_icons"
              :key="t.name"
              @click="updateIcon(t.icon, 'party_2')"
            >
              {{ t.team }}
            </li>
          </ul>
        </div>
        <TeamIcon :icon="props.subevent.logo_2"></TeamIcon>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col">
        <i class="bi bi-x-lg float-end" @click="close()"></i>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <input type="text" class="form-control" v-model.trim="name" />
      </div>
      <div class="col">
        <input type="text" class="form-control" v-model.trim="party_1" />
      </div>
      <div class="col">
        <input type="text" class="form-control" v-model.trim="party_2" />
      </div>
      <div class="col">
        <input
          type="date"
          class="form-control"
          placeholder="Date"
          v-model.trim="eventdate"
        />
      </div>
      <div class="col">
        <input
          type="datetime-local"
          class="form-control"
          placeholder="Date"
          v-model.trim="firstbid"
        />
      </div>
      <div class="col">
        <input
          type="datetime-local"
          class="form-control"
          placeholder="Date"
          v-model.trim="lastbid"
        />
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder="New option..."
          v-model.trim="option"
          @keyup.enter="addLocalOdd"
        />
      </div>
      <div class="col">
        <ul class="list-group mb-1">
          <li
            class="list-group-item smalllist"
            v-for="o in sorted_odds"
            :key="o"
          >
            <div class="row">
              <div class="col">
                <i
                  class="bi bi-dash"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'minus')"
                ></i>
                {{ o.sno }}

                <i
                  class="bi bi-plus"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'plus')"
                ></i>
              </div>
              <div class="col">
                <span @click="selectThis(o.id)" class="border rounded">{{
                  o.name
                }}</span>
                <i
                  class="fa fa-arrows-h mx-2 float-end"
                  style="font-size: 18px; color: blue"
                  v-if="isNeutral(o.id)"
                ></i>
              </div>
              <div class="col">
                <div v-if="selectedOption == o.id">
                  <span
                    class="badge rounded-pill bg-light text-dark"
                    v-if="isNeutral(o.id)"
                    @click="clearNeutral(o.id)"
                  >
                    <i
                      class="fa fa-arrows-h mx-2 float-end"
                      style="font-size: 12px"
                    ></i>
                    <i class="bi bi-x-circle"></i>
                  </span>
                  <span
                    class="badge rounded-pill bg-primary"
                    v-if="!isNeutral(o.id)"
                    @click="addNeutral(o.id)"
                  >
                    <i
                      class="fa fa-arrows-h mx-2 float-end"
                      style="font-size: 12px"
                    ></i>
                    <i class="bi bi-plus-circle"></i>
                  </span>
                  <i
                    class="bi bi-trash float-end"
                    @click="deleteOption(o.id)"
                  ></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col">
        <span
          class="badge rounded-pill bg-warning float-start"
          @click="allOptionsNeutral()"
          v-if="neutraloptions.length != sorted_odds.length"
          >All
          <i
            class="fa fa-arrows-h mx-2 float-end"
            style="font-size: 18px; color: blue"
          ></i>
        </span>
      </div>
      <div class="col">
        <button
          class="btn btn-sm btn-primary float-end"
          @click="updateSubevent()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, onMounted, computed, defineEmits } from "vue";
import TeamIcon from "./TeamIcon.vue";
import { doc, updateDoc } from "firebase/firestore";
const emit = defineEmits(["subeventupdated"]);
import { db } from "../../firebase/config";
import { useStore } from "../../store/index";
const store = useStore();
const dayjs = require("dayjs");
const props = defineProps(["subevent"]);
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const name = ref("");
const eventdate = ref("");
const firstbid = ref("");
const option = ref("");
const lastbid = ref("");
const neutraloptions = ref([]);
const oddsoptions = ref({});
const party_1 = ref("");
const party_2 = ref("");

const selectedOption = ref("");
const iconfiles = ref([]);
const updateIcon = async (f, p) => {
  const washingtonRef = doc(db, "subevents", props.subevent.id);
  if (p == "party_1") {
    await updateDoc(washingtonRef, {
      logo_1: f,
    });
  }
  if (p == "party_2") {
    await updateDoc(washingtonRef, {
      logo_2: f,
    });
  }
};
function unixToDatetimeLocal(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
onMounted(() => {
  name.value = props.subevent.name;
  eventdate.value = props.subevent.eventdate;
  firstbid.value = unixToDatetimeLocal(props.subevent.firstbid);
  lastbid.value = unixToDatetimeLocal(props.subevent.lastbid);
  neutraloptions.value = props.subevent.neutraloptions;
  oddsoptions.value = props.subevent.oddsoptions;
  party_1.value = props.subevent.party_1;
  party_2.value = props.subevent.party_2;

  populateIconFiles();
});
const populateIconFiles = () => {
  for (const property in store.masterdata.teams) {
    //console.log(`${property}: ${object[property]}`);
    if (
      !iconfiles.value.includes({
        team: store.masterdata.teams[property].name,
        icon: store.masterdata.teams[property].icon,
      })
    ) {
      iconfiles.value.push({
        team: store.masterdata.teams[property].name,
        icon: store.masterdata.teams[property].icon,
      });
    }
  }
  return;
};
const sorted_icons = computed(() => {
  var arr = iconfiles.value;

  let sorted_arr = sortByField(arr, "team");
  return sorted_arr;
});
const sorted_odds = computed(() => {
  var arr = [];
  for (const property in oddsoptions.value) {
    arr.push({
      id: property,
      ...oddsoptions.value[property],
    });
  }
  let sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const selectThis = (id) => {
  if (selectedOption.value != id) {
    selectedOption.value = id;
  } else {
    selectedOption.value = "";
  }
};
const isNeutral = (id) => {
  if (neutraloptions.value.includes(id)) return true;

  return false;
};
const updateSno = (id, sno, action) => {
  var new_sno = parseInt(sno);
  if (action == "plus") {
    new_sno++;
  }
  if (action == "minus") {
    new_sno--;
  }

  oddsoptions.value[id].sno = new_sno;
};
const deleteOption = (id) => {
  delete oddsoptions.value[id];
};
const clearNeutral = (id) => {
  const index = neutraloptions.value.indexOf(id);
  if (index > -1) {
    // only splice array when item is found
    neutraloptions.value.splice(index, 1); // 2nd parameter means remove one item only
  }
};
const addNeutral = (id) => {
  neutraloptions.value.push(id);
};
const allOptionsNeutral = () => {
  sorted_odds.value.forEach((o) => {
    if (!neutraloptions.value.includes(o.id)) {
      addNeutral(o.id);
    }
  });
};
const addLocalOdd = () => {
  const oddObj = {
    name: option.value,
    sno: getLargestSno(),
  };
  oddsoptions.value[nanoid()] = oddObj;
  option.value = "";
};
const getLargestSno = () => {
  return Object.keys(oddsoptions.value).length + 1;
};
const updateSubevent = async () => {
  const washingtonRef = doc(db, "subevents", props.subevent.id);
  const subObj = {
    name: name.value,
    eventdate: eventdate.value,
    firstbid: dayjs(firstbid.value).unix(),
    lastbid: dayjs(lastbid.value).unix(),
    neutraloptions: neutraloptions.value,
    oddsoptions: oddsoptions.value,
    party_1: party_1.value,
    party_2: party_2.value,
  };
  await updateDoc(washingtonRef, subObj);
  emit("subeventupdated", true);
};
const close = () => {
  emit("subeventupdated", true);
};
</script>
<style scoped>
.form-control {
  font-size: 12px;
}
.smalllist {
  padding: 2px;
  font-size: 12px;
}
</style>
