<template>
  <div class="container">
    <UserNav></UserNav>
    <div class="card mt-2 mx-auto my-container" style="width: 20rem">
      <div class="card-header smalltxt d-flex justify-content-around">
        <!-- <span
          ><i
            class="bi bi-gear"
            @click="togglesettings = !togglesettings"
            v-if="!togglesettings"
          ></i>
          <i
            class="bi bi-x-lg glow-danger"
            @click="togglesettings = !togglesettings"
            v-if="togglesettings"
          ></i
        ></span> -->

        <h6 class="zerobottom align-middle">Information</h6>

        <span class="badge rounded-pill bg-success badge-pill" @click="toMain()"
          >Back</span
        >
      </div>
      <div class="card-body">
        <div class="row" v-if="togglesettings">
          <div class="col">
            <UserSettings></UserSettings>
          </div>
        </div>
        <div @click="togglesettings = false">
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-check2-all text-primary"></i>
            </div>
            <div class="col smalltxt">Win!</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <span
                class="badge rounded-pill mx-auto bg-danger text-center padding2"
                >0:42
              </span>
            </div>
            <div class="col smalltxt d-flex align-items-center">
              Time remaining
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <ul class="list-group btn-shadow">
                <li class="list-group-item smalltxt pastelred padding0">
                  Team Red
                </li>
                <li class="list-group-item smalltxt pastelblue padding0">
                  Team Blue
                </li>
              </ul>
            </div>
            <div class="col smalltxt d-flex align-items-center">
              Click to select
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <ul class="list-group btn-shadow">
                <li class="list-group-item smalltxt coral padding0">My pick</li>
              </ul>
            </div>
            <div class="col smalltxt">Click to delete</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <span
                class="badge rounded-pill mx-auto bg-secondary text-center padding2"
                >Closed
              </span>
            </div>
            <div class="col smalltxt d-flex align-items-center">
              Game closed
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <!-- <i class="bi bi-arrow-down-circle i14"></i> -->
              <ul class="list-group btn-shadow">
                <li class="list-group-item smalltxt padding0">Odds</li>
              </ul>
            </div>
            <div class="col smalltxt">Result & favs</div>
          </div>

          <!-- <div class="row">
            <div class="col text-center">
              <i class="bi bi-chevron-double-down glow-secondary"></i>
            </div>
            <div class="col smalltxt">Match closed</div>
          </div> -->
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-award text-primary"></i>
            </div>
            <div class="col smalltxt">Prizes</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-trophy text-primary"></i>
            </div>
            <div class="col smalltxt">Leaderboard</div>
          </div>

          <div class="row">
            <div class="col text-center">
              <i class="bi bi-arrow-clockwise text-success"></i>
            </div>
            <div class="col smalltxt">Refresh app</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-x-circle mx-1"></i>
            </div>
            <div class="col smalltxt">Exit event</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-card-checklist"></i>
            </div>
            <div class="col smalltxt">Scorecard</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-pen text-primary"></i>
            </div>
            <div class="col smalltxt">Edit name</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-gear"></i>
            </div>
            <div class="col smalltxt">Settings & contact</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-info-circle"></i>
            </div>
            <div class="col smalltxt">Information</div>
          </div>

          <div class="row">
            <div class="col text-center">
              <i class="bi bi-power"></i>
            </div>
            <div class="col smalltxt">Logout</div>
          </div>
          <div class="row">
            <div class="col text-center">
              <i class="bi bi-house"></i>
            </div>
            <div class="col smalltxt">Starter's hut</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import UserNav from "../navbars/UserNav.vue";
import { useRouter } from "vue-router";
import UserSettings from "../user/UserSettings.vue";
import { ref } from "vue";
const router = useRouter();
const togglesettings = ref(false);
const toMain = () => {
  router.push("/home");
};
</script>
<style scoped>
.glow-warning {
  color: var(--bs-warning);
  filter: drop-shadow(0 0 10px var(--bs-warning));
}
.glow-primary {
  color: var(--bs-primary);
  filter: drop-shadow(0 0 10px var(--bs-primary));
}
.glow-secondary {
  color: var(--bs-secondary);
  filter: drop-shadow(0 0 10px var(--bs-secondary));
}
.glow-danger {
  color: var(--bs-danger);
  filter: drop-shadow(0 0 10px var(--bs-danger));
}
.glow-success {
  color: var(--bs-success);
  filter: drop-shadow(0 0 10px var(--bs-success));
}
.smalltxt {
  font-size: 14px;
}
.lesspadding {
  padding: 3px;
}
.coral {
  background-color: #cb99c9;
  padding: 3px;
}
.zerobottom {
  margin-bottom: 0px;
}
.card-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  margin: 5px;
}
.pastelred {
  background-color: #ffa29a;
}
.pastelblue {
  background-color: #9acdff;
}
/* .badge-pill {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 2rem;
}
.usericons {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 2rem;
} */
.my-container {
  background: linear-gradient(to bottom, #fcfcfc, #fafafa);
}
.pastelyellow {
  background-color: #fefdc0;
}
.padding0 {
  padding: 0px;
}
.btn-shadow {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3); /* Light shadow */
  transition: box-shadow 0.3s ease;
}
</style>
