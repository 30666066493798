<template>
  <div class="card mx-auto mt-5" style="width: 18rem">
    <div class="card-body">
      <h5 class="card-title">
        Odds! Admin Login
        <i class="bi bi-person float-end" @click="toUserLogin()"></i>
      </h5>
      <div class="card-text">
        <div class="row mt-1">
          <input
            type="text"
            class="form-control col m-1"
            placeholder="Email"
            v-model.trim="email"
          />
        </div>
        <div class="row mt-1">
          <input
            type="password"
            class="form-control col m-1"
            placeholder="Password"
            v-model.trim="password"
          />
        </div>
        <div class="row mt-1">
          <button class="btn btn-primary col m-1" @click="login()">
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { db, auth } from "@/firebase/config";
import { useRouter } from "vue-router";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useStore } from "@/store/index";
import { doc, getDoc, query, collection, where } from "firebase/firestore";
import { storeToRefs } from "pinia";
const router = useRouter();
const store = useStore();
const email = ref("");
const password = ref("");
const toUserLogin = () => {
  router.push("/");
};
const login = () => {
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      store.setUser(user.uid);
      if (user.email) {
        store.setAdmin();
      }
      router.push("/event");

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};
</script>
