<template>
  <div class="border rounded border-primary mx-1">
    <div class="row">
      <div class="float-end">
        <i class="bi bi-x-circle float-end" @click="clearMaster()"></i>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3>
          {{ masterData.name }}
          <span
            class="badge rounded-pill bg-success"
            @click="toggleActive(masterData.id)"
            v-if="masterData.active"
            >Active</span
          >
          <span
            class="badge rounded-pill bg-danger"
            @click="toggleActive(masterData.id)"
            v-if="!masterData.active"
            >Deactivated</span
          >
        </h3>
      </div>
      <div class="col">
        <span @click="toggleOddsOptions()" v-if="!showOddsOptions"
          >Odds options <i class="bi bi-caret-down"></i
        ></span>
        <i
          class="bi bi-x-circle float-end"
          v-if="showOddsOptions"
          @click="toggleOddsOptions()"
        ></i>
        <ul class="list-group" v-if="showOddsOptions">
          <li
            class="list-group-item smalllist"
            v-for="o in sorted_odds"
            :key="o"
          >
            <i
              class="bi bi-dash"
              v-if="selectedOption == o.id"
              @click="updateSno(o.id, o.sno, 'minus')"
            ></i>
            {{ o.sno }}
            <i
              class="bi bi-plus"
              v-if="selectedOption == o.id"
              @click="updateSno(o.id, o.sno, 'plus')"
            ></i>
            <span @click="selectThis(o.id)">{{ o.name }}</span>

            <i
              class="bi bi-trash float-end"
              v-if="selectedOption == o.id"
              @click="deleteOption(o.id)"
            ></i>
          </li>
        </ul>
      </div>
      <div class="col">
        <i
          class="bi bi-caret-down mx-1"
          v-if="masterData.subeventsort == 'desc' || !masterData.subeventsort"
          @click="setSubEventOrder('asc')"
        ></i
        ><i
          class="bi bi-caret-up mx-1"
          v-if="masterData.subeventsort == 'asc'"
          @click="setSubEventOrder('desc')"
        ></i>
      </div>
      <div class="col">
        <MasterEventIcon
          :masterevent="masterData.id"
          v-if="!masterData.eventicon"
        ></MasterEventIcon>
        <MasterIcon :icon="masterData.eventicon"></MasterIcon>
      </div>
      <div class="col">
        <span
          class="badge rounded-pill bg-success"
          @click="togglePrediction(masterData.id)"
          v-if="masterData.predictivebids"
          >TeamPredict: ON</span
        >
        <span
          class="badge rounded-pill bg-danger"
          v-if="!masterData.predictivebids"
          @click="togglePrediction(masterData.id)"
          >TeamPredict: OFF</span
        >
      </div>
      <div class="col">
        <button
          class="btn btn-primary btn-sm float-end"
          @click="toggleNewSubEvent()"
        >
          New SubEvent
        </button>
      </div>
    </div>
    <div class="row" v-if="showNewSubEvent">
      <NewSubEvent
        @subeventsubmitted="showNewSubEvent = false"
        :oddsoptions="masterData.oddsoptions"
        :parentevent="masterData.id"
        :parentname="masterData.name"
        :neutraloptions="masterData.neutraloptions"
        :eventdate="masterData.eventdate"
        :firstbid="masterData.firstbid"
        :lastbid="masterData.lastbid"
      ></NewSubEvent>
    </div>
    <div class="row" v-if="masterData.id">
      <!-- <SubEventList :parentevent="masterData.id"></SubEventList> -->
      <SubEventTemplate
        :parentevent="masterData.id"
        :subeventsort="masterData.subeventsort"
      ></SubEventTemplate>
    </div>
  </div>
</template>
<script setup>
import { ref, onBeforeUnmount, onMounted, computed } from "vue";
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import SubEventTemplate from "./SubEventTemplate.vue";
import MasterEventIcon from "./MasterEventIcon.vue";
import MasterIcon from "./MasterIcon.vue";
import NewSubEvent from "./NewSubEvent.vue";

var unsub = () => {};
const store = useStore();
const showNewSubEvent = ref(false);
const showOddsOptions = ref(false);
const clearMaster = () => {
  store.clearMasterEvent();
};
const setSubEventOrder = async (order) => {
  const washingtonRef = doc(db, "masterevents", masterData.value.id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    subeventsort: order,
  });
};
const toggleOddsOptions = () => {
  showOddsOptions.value = !showOddsOptions.value;
};
const toggleNewSubEvent = () => {
  showNewSubEvent.value = !showNewSubEvent.value;
};
const masterData = ref({});
onMounted(() => {
  unsub = onSnapshot(doc(db, "masterevents", store.masterevent), (docy) => {
    masterData.value = { id: docy.id, ...docy.data() };
  });
});
onBeforeUnmount(() => {
  unsub();
});
const sorted_odds = computed(() => {
  var arr = [];
  for (const property in masterData.value.oddsoptions) {
    arr.push({
      id: property,
      ...masterData.value.oddsoptions[property],
    });
  }
  let sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const toggleActive = async () => {
  //console.log("toggleActive=>", masterData.value.id);
  const washingtonRef = doc(db, "masterevents", masterData.value.id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    active: !masterData.value.active,
  });
};
const togglePrediction = async () => {
  const washingtonRef = doc(db, "masterevents", masterData.value.id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    predictivebids: !masterData.value.predictivebids,
  });
};
</script>
<style scoped>
.smalllist {
  padding: 2px;
  font-size: 12px;
}
.rounded-pill {
  font-size: 8px;
}
.double-height {
  height: 200vh;
}
</style>
