<template>
  <router-view />
</template>
<!-- <script setup>
import { onMounted } from "vue";
import { messaging, db } from "./firebase/config";
import { getToken } from "firebase/messaging";
import { collection, addDoc } from "firebase/firestore";
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      //generate token here
      getToken(messaging, {
        vapidKey:
          "BFnmaXsRmRce1flyM_rzo-2UhvKqKqC8BM5E4b09UoDDD5rAlxykKaocwpHYt0KMZ6alvdPXUyvgZRC_GjGqC9w",
      })
        .then(async (currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log("CURRENT TOKEN:", currentToken);
            await addDoc(collection(db, "fcmtokens"), {
              token: currentToken,
            });
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    } else if (permission === "denied") {
      alert(
        "Permission denied. Notification permission required for app to work."
      );
    }
  });
}
onMounted(() => {
  requestPermission();
});
</script> -->
