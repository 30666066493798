<template>
  <ul class="list-group mt-1">
    <li
      class="list-group-item overflow-hidden niltopbottompadding bgg"
      v-for="s in sorted_subevents"
      :key="s.id"
    >
      <SubEvent :subevent="s"></SubEvent>
      <SubeventList :parentevent="s.id" :sortby="s.subeventsort"></SubeventList>
    </li>
  </ul>
</template>
<script setup>
import SubeventList from "./SubeventList.vue";
import TeamIcon from "../user/TeamIcon.vue";
import SubEvent from "./SubEvent.vue";
import { defineProps, computed } from "vue";
const props = defineProps(["parentevent", "sortby"]);
import { useStore } from "../../store/index";
import { useSubevents } from "../../store/subevents";
const store = useStore();
const se = useSubevents();
var sorted_subevents = computed(() => {
  var arr = [];
  se.subevents.forEach((s) => {
    if (s.parentevent == props.parentevent) {
      arr.push(s);
    }
  });
  var sorted_arr = sortByField(arr, "sno");

  return sorted_arr;
});
function sortByField(arr, field) {
  if (props.sortby == "asc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] > b[field]) {
        return 1;
      } else if (a[field] < b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  if (props.sortby == "desc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
</script>
<style scoped>
.zeropadding {
  padding: 0px;
}
.niltopbottompadding {
  padding-top: 1px;
  padding-bottom: 1px;
}
/* .list-group-item {
  background: linear-gradient(to right, #f0f0f0, white, #f0f0f0);
} */
.bgg {
  background: linear-gradient(to bottom right, #fcfcfc, #fafafa);
}
</style>
