<template>
  <!-- {{ bidsarr.length }}, {{ maxid }}, {{ maxcount }} -->
  <div v-if="bidsarr.length" class="overflow-hidden border rounded bgg">
    <div class="row rounded">
      <div class="col">
        <div class="card">
          <div class="card-body bg-info d-flex justify-content-around rounded">
            <span class="d-flex align-items-center">
              <MedIcon
                :icon="se.subevent.logo_1"
                v-if="se.subevent.result && showIcon('party_1')"
              ></MedIcon>
              <MedIcon
                :icon="se.subevent.logo_2"
                v-if="se.subevent.result && showIcon('party_2')"
              ></MedIcon>
              <h6 v-if="se.subevent.result" class="zeromargin mb-0">
                {{ resultString(se.subevent.result) }}
              </h6>
              <h6 class="zeromargin" v-else>Result awaited...</h6></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col text-center"><h6>Favourites</h6></div>
      <div class="col text-center"><h6>My pick</h6></div>
      <div class="col text-center"><h6>Underdogs</h6></div>
    </div>
    <Transition
      ><div class="row" v-if="true">
        <div class="col text-center">
          <TinyIcon :icon="fav_icon"></TinyIcon>
        </div>
        <div class="col text-center">
          <TinyIcon :icon="mypic_icon"></TinyIcon>
        </div>
        <div class="col text-center">
          <TinyIcon :icon="ud_icon"></TinyIcon>
        </div></div
    ></Transition>

    <div class="row">
      <div class="col text-center">
        {{ displayString("max") }}
      </div>
      <div class="col text-center">
        {{ yourChoice() }}
      </div>
      <div class="col text-center">
        {{ displayString("min") }}
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h3>{{ displayPercent("max") }}%</h3>
      </div>
      <div class="col text-center">
        <h3>{{ yourPercentage() }}</h3>
      </div>
      <div class="col text-center">
        <h3>{{ displayPercent("min") }}%</h3>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <div class="row" v-if="!bidsarr.length">
      <div class="col text-center">No bids submitted</div>
    </div>
  </div> -->
</template>
<script setup>
import MedIcon from "./MedIcon.vue";
import TinyIcon from "../events/TinyIcon.vue";
import { useStore } from "../../store/index";
import { useSubevents } from "../../store/subevents";
import { useBids } from "../../store/bids";
import { watchEffect, ref, computed } from "vue";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase/config";

var bidsarr = ref([]);
var maxstr = ref("");
var minstr = ref("");
var maxcount = ref(0);
var mincount = ref(0);
const fav_icon = ref("");
const mypic_icon = ref("");
const ud_icon = ref("");
const allBids = ref({});
const se = useSubevents();
const bs = useBids();
const showIcon = (party) => {
  var r = false;
  if (se.subevent.result.party == party) {
    r = true;
  }
  if (se.subevent.result.party == "NeutralBid") {
    r = true;
  }
  return r;
};
function formatTime(seconds) {
  if (!se.subevent.acceptbids) {
    return "Closed";
  }
  if (seconds < 0) {
    return "Closed";
  }
  if (seconds < 60) {
    return "less than a minute left";
  }
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""} and ${remainingMinutes} min${
      remainingMinutes !== 1 ? "s" : ""
    } left`;
  } else {
    return `${minutes} min${minutes !== 1 ? "s" : ""} left`;
  }
}

const computeFrequency = () => {
  const frequency = {};

  // Step 2: Count each combination of bidid and party
  bidsarr.value.forEach((item) => {
    const key = `${item.bidid}#~#${item.party}`;
    frequency[key] = (frequency[key] || 0) + 1;
  });

  // Step 3: Find the most and least frequent combinations
  let maxCombination = null;
  let minCombination = null;
  let maxCount = -Infinity;
  let minCount = Infinity;

  for (const key in frequency) {
    const count = frequency[key];

    if (count > maxCount) {
      maxCount = count;
      maxCombination = key;
    }

    if (count < minCount) {
      minCount = count;
      minCombination = key;
    }
  }
  maxstr.value = maxCombination;
  maxcount.value = maxCount;
  minstr.value = minCombination;
  mincount.value = minCount;
};
const getBidsObj = async () => {
  const docRef = doc(db, "bids", se.subevent.bidsobject);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Outcomes Component =>", docSnap.data());
    allBids.value = { ...docSnap.data() };
    bidsarr.value = [];
    for (const property in docSnap.data().bids) {
      bidsarr.value.push(docSnap.data().bids[property]);
    }
    computeFrequency();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
watchEffect(() => {
  if (formatTime(se.subevent.lastbid - bs.timenow) == "Closed") {
    //console.log("inside outcome ccomponent: check DB NOW");
    getBidsObj();
  } else {
    console.log("Dont check DB as yet...");
  }
});
const sorted_odds = computed(() => {
  var arr = [];
  for (const property in se.subevent.oddsoptions) {
    arr.push({
      id: property,
      ...se.subevent.oddsoptions[property],
    });
  }

  return arr;
});
const oddsName = (n) => {
  var name = "";
  sorted_odds.value.forEach((s) => {
    if (s.id == n) {
      name = s.name;
    }
  });
  return name;
};
const displayString = (mm) => {
  var str = "";
  if (mm == "max") {
    const bits = maxstr.value.split("#~#");
    if (bits[1] != "NeutralBid") {
      str = se.subevent[bits[1]] + " to " + oddsName(bits[0]);
      //se.subevent[bits[1]] + " to " + se.subevent.oddsoptions[bits[0]].name;
      if (bits[1] == "party_1") {
        fav_icon.value = se.subevent.logo_1;
      }
      if (bits[1] == "party_2") {
        fav_icon.value = se.subevent.logo_2;
      }
    } else {
      str = oddsName(bits[0]);
    }
  }
  if (mm == "min") {
    const bits = minstr.value.split("#~#");
    if (bits[1] != "NeutralBid") {
      str = se.subevent[bits[1]] + " to " + oddsName(bits[0]);
      //se.subevent[bits[1]] + " to " + se.subevent.oddsoptions[bits[0]].name;
      if (bits[1] == "party_1") {
        ud_icon.value = se.subevent.logo_1;
      }
      if (bits[1] == "party_2") {
        ud_icon.value = se.subevent.logo_2;
      }
    } else {
      str = oddsName(bits[0]);
    }
  }

  return str;
};
const displayPercent = (mm) => {
  var percent = 0;
  if (mm == "max") {
    percent = ((maxcount.value / bidsarr.value.length) * 100).toFixed(0);
  }
  if (mm == "min") {
    percent = ((mincount.value / bidsarr.value.length) * 100).toFixed(0);
  }
  return percent;
};
const yourChoice = () => {
  var choice = "";
  // console.log(
  //   "AllBids=>",
  //   allBids.value.bids[getFirst8Chars(auth.currentUser.uid)]
  // );
  if (checkIfUserHasSubmittedBid()) {
    const bidvalue = allBids.value.bids[getFirst8Chars(auth.currentUser.uid)];
    if (bidvalue.party != "NeutralBid") {
      choice = se.subevent[bidvalue.party] + " to " + oddsName(bidvalue.bidid);
      //mypic_icon.value = bidvalue.party;
      if (bidvalue.party == "party_1") {
        mypic_icon.value = se.subevent.logo_1;
      }
      if (bidvalue.party == "party_2") {
        mypic_icon.value = se.subevent.logo_2;
      }
    } else {
      choice = oddsName(bidvalue.bidid);
    }
  } else {
    choice = "Not submitted";
  }
  return choice;
};
const yourPercentage = () => {
  var percent = 0;
  if (checkIfUserHasSubmittedBid()) {
    const userBid = allBids.value.bids[getFirst8Chars(auth.currentUser.uid)];
    var count = 0;
    bidsarr.value.forEach((b) => {
      if (b.bidid == userBid.bidid && b.party == userBid.party) {
        count++;
      }
    });
    percent = ((count / bidsarr.value.length) * 100).toFixed(0);
    percent = percent + "%";
  } else {
    percent = "N/A";
  }

  return percent;
};
function getFirst8Chars(str) {
  return str.substring(0, 8);
}
const checkIfUserHasSubmittedBid = () => {
  var hasSubmitted = false;
  for (const property in allBids.value.bids) {
    if (property == getFirst8Chars(auth.currentUser.uid)) {
      hasSubmitted = true;
    }
  }
  //console.log("checking if submitted=>", hasSubmitted);
  return hasSubmitted;
};
const resultString = (result) => {
  var str = "";
  if (result.party != "NeutralBid") {
    str = se.subevent[result.party] + " " + oddsName(result.bidid);
  } else {
    str = oddsName(result.bidid);
  }
  return str;
};
</script>
<style scoped>
.col {
  font-size: 12px;
}
.card-body {
  padding: 5px;
}
.zeromargin {
  margin: 0px;
}
.padding1 {
  padding: 1px;
}
.bgg {
  background: linear-gradient(to bottom right, #fcfcfc, #fafafa);
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
