<template>
  <div class="container border rounded double-height-container">
    <!-- {{ props.parentevent }}- {{ props.oddsoptions }} -->
    <div class="row mt-1">
      <div class="col">
        New subevent for :
        <span class="bg-warning border rounded">{{ props.parentname }}</span>
      </div>
    </div>
    <div class="row mt-1">
      <!-- {{ store.masterdata }} -->
      <div class="col">
        {{ sno }}
        Name :
        <div class="float-end">
          <i
            class="bi bi-caret-down"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton_k1"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton_k1">
            <li
              class="dropdown-item"
              v-for="t in event_names_list"
              :key="t"
              @click="setEventName(t)"
            >
              {{ t }}
            </li>
          </ul>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Name..."
          v-model.trim="name"
        />
      </div>
      <div class="col">
        Party_1:
        <div class="float-end">
          <i
            class="bi bi-caret-down"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton1"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              class="dropdown-item"
              v-for="t in list_1"
              :key="t.id"
              @click="setParty(t.name, 'party_1')"
            >
              {{ t.name }}
            </li>
          </ul>
        </div>
        <textarea
          class="form-control"
          placeholder="Party_1"
          v-model.trim="party_1"
        />
      </div>
      <div class="col">
        Party_2:
        <div class="float-end">
          <i
            class="bi bi-caret-down"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton2"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <li
              class="dropdown-item"
              v-for="t in list_2"
              :key="t.id"
              @click="setParty(t.name, 'party_2')"
            >
              {{ t.name }}
            </li>
          </ul>
        </div>
        <textarea
          class="form-control"
          placeholder="Party_2"
          v-model.trim="party_2"
        />
      </div>

      <div class="col">
        Event Date:
        <input
          type="date"
          class="form-control"
          placeholder="Date"
          v-model.trim="eventdate"
        />
      </div>
      <div class="col">
        Opening Bids:
        <input
          type="datetime-local"
          class="form-control"
          placeholder="Last bid date"
          v-model.trim="firstbid"
        />
      </div>
      <div class="col">
        Closing Bid:
        <input
          type="datetime-local"
          class="form-control"
          placeholder="Last bid time"
          v-model.trim="lastbid"
        />
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <div class="row">
          <div class="col">
            New Odd:
            <input
              type="text"
              class="form-control"
              v-model.trim="option"
              @keyup.enter="addLocalOdd"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <ul class="list-group mb-1">
          <li
            class="list-group-item smalllist"
            v-for="o in sorted_odds"
            :key="o"
          >
            <div class="row">
              <div class="col">
                <i
                  class="bi bi-dash"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'minus')"
                ></i>
                {{ o.sno }}
                <i
                  class="bi bi-plus"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'plus')"
                ></i>
              </div>
              <div class="col">
                <span @click="selectThis(o.id)">{{ o.name }}</span>
                <i
                  class="fa fa-arrows-h mx-2 float-end"
                  style="font-size: 18px; color: blue"
                  v-if="isNeutral(o.id)"
                ></i>
              </div>
              <div class="col">
                <div v-if="selectedOption == o.id">
                  <span
                    class="badge rounded-pill bg-light text-dark"
                    v-if="isNeutral(o.id)"
                    @click="clearNeutral(o.id)"
                  >
                    <i
                      class="fa fa-arrows-h mx-2 float-end"
                      style="font-size: 12px"
                    ></i>
                    <i class="bi bi-x-circle"></i>
                  </span>
                  <span
                    class="badge rounded-pill bg-primary"
                    v-if="!isNeutral(o.id)"
                    @click="addNeutral(o.id)"
                  >
                    <i
                      class="fa fa-arrows-h mx-2 float-end"
                      style="font-size: 12px"
                    ></i>
                    <i class="bi bi-plus-circle"></i>
                  </span>
                  <i
                    class="bi bi-trash float-end"
                    @click="deleteOption(o.id)"
                  ></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col">
        <button
          class="btn btn-sm btn-primary"
          v-if="checkIfFieldsFilled"
          @click="addSubEvent()"
        >
          Add Sub Event
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";

import { ref, computed, defineProps, onMounted, defineEmits } from "vue";
import {
  doc,
  updateDoc,
  onSnapshot,
  deleteField,
  getDoc,
  addDoc,
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const option = ref("");
const props = defineProps([
  "oddsoptions",
  "parentevent",
  "parentname",
  "neutraloptions",
  "eventdate",
  "firstbid",
  "lastbid",
]);
function unixToDatetimeLocal(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
const store = useStore();
const emit = defineEmits(["subeventsubmitted"]);
const oddsoptions = ref({ ...props.oddsoptions });
const neutraloptions = ref(props.neutraloptions);
const teams = ref({ ...store.masterdata.teams });
const dayjs = require("dayjs");
const selectedOption = ref("");
const name = ref("");
const eventdate = ref(props.eventdate);
const party_1 = ref("");
const party_2 = ref("");
const sno = ref(0);
const parent_event_data = ref({});
const firstbid = ref(unixToDatetimeLocal(props.firstbid));
const lastbid = ref(unixToDatetimeLocal(props.lastbid));
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const event_names_list = ref([
  // "Singles 1",
  // "Singles 2",
  "Game 1",
  "Game 2",
  "Game 3",
  "Game 4",
  "Game 5",
  "Game 6",
  "Game 7",
  "Game 8",
  "Game 9",
  "Game 10",
]);
const setEventName = (str) => {
  if (name.value != str) {
    name.value = str;
  } else {
    name.value = "";
  }
};
onMounted(async () => {
  const coll = collection(db, "subevents");
  const q = query(coll, where("parentevent", "==", props.parentevent));
  const snapshot = await getCountFromServer(q);
  sno.value = snapshot.data().count + 1;
  const docRef = doc(db, "subevents", props.parentevent);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    parent_event_data.value = { id: docSnap.id, ...docSnap.data() };
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
});
const checkIfFieldsFilled = computed(() => {
  var ok = true;
  if (!name.value) {
    ok = false;
  }
  if (!eventdate.value) {
    ok = false;
  }
  if (!firstbid.value) {
    ok = false;
  }
  if (!lastbid.value) {
    ok = false;
  }
  return ok;
});

const sorted_odds = computed(() => {
  var arr = [];
  for (const property in oddsoptions.value) {
    arr.push({
      id: property,
      ...oddsoptions.value[property],
    });
  }
  let sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}

const list_1 = computed(() => {
  var arr = [];
  if (props.parentevent == store.masterevent) {
    for (const property in teams.value) {
      arr.push({
        id: property,
        name: teams.value[property].name,
      });
    }
  }
  if (parent_event_data.value.id) {
    ///incase parentevent is the masterevent then parent_event_data.value.id will be null
    //if parent_event_data.value.id exists it means that the parent event is not a master event
    for (const property in teams.value) {
      if (teams.value[property].name == parent_event_data.value.party_1) {
        for (const prop in teams.value[property].players)
          arr.push({
            name: teams.value[property].players[prop].player,
          });
      }
    }
  }
  let sorted_arr = sortByField(arr, "name");
  return sorted_arr;
});
const list_2 = computed(() => {
  var arr = [];
  if (props.parentevent == store.masterevent) {
    for (const property in teams.value) {
      arr.push({
        id: property,
        name: teams.value[property].name,
      });
    }
  }
  if (parent_event_data.value.id) {
    ///incase parentevent is the masterevent then parent_event_data.value.id will be null
    //if parent_event_data.value.id exists it means that the parent event is not a master event
    for (const property in teams.value) {
      if (teams.value[property].name == parent_event_data.value.party_2) {
        for (const prop in teams.value[property].players)
          arr.push({
            name: teams.value[property].players[prop].player,
          });
      }
    }
  }
  let sorted_arr = sortByField(arr, "name");
  return sorted_arr;
});

const getLargestSno = () => {
  return Object.keys(oddsoptions.value).length + 1;
};
const addLocalOdd = () => {
  const oddObj = {
    name: option.value,
    sno: getLargestSno(),
  };
  oddsoptions.value[nanoid()] = oddObj;
  option.value = "";
};
const selectThis = (id) => {
  if (selectedOption.value != id) {
    selectedOption.value = id;
  } else {
    selectedOption.value = "";
  }
};
const deleteOption = (id) => {
  delete oddsoptions.value[id];
};
const updateSno = async (id, sno, action) => {
  var new_sno = parseInt(sno);
  if (action == "plus") {
    new_sno++;
  }
  if (action == "minus") {
    new_sno--;
  }

  oddsoptions.value[id].sno = new_sno;
};
const isNeutral = (id) => {
  if (neutraloptions.value.includes(id)) return true;

  return false;
};
const addSubEvent = async () => {
  const subObj = {
    admin: store.userid,
    masterevent: store.masterevent,
    parentevent: props.parentevent,
    name: name.value,
    oddsoptions: oddsoptions.value, //various options for bids
    eventdate: eventdate.value, //used for sorting of events
    firstbid: dayjs(firstbid.value).unix(), // no bids accepted before this time
    lastbid: dayjs(lastbid.value).unix(), // no bids accepted after this time
    party_1: party_1.value.trim(), //.trim() is added because v-model.trim does not work on text area
    logo_1: null, // link to logo of party_1
    party_2: party_2.value.trim(), //.trim() is added because v-model.trim does not work on text area
    logo_2: null, //link to logo of party_2
    bids: [], //bids submitted by users
    result: null, // the id of correct oddsoptions that is the correct outcome
    sno: sno.value, //for order of sub events within a master/parent event
    publish: false, //publish this even to users on their screens
    acceptbids: true, //if this is false no bids - this is when subevent is holder of other subevents
    active: false, //this event will only show in preview
    subeventsort: "asc", //sorting order of subevents
    neutraloptions: neutraloptions.value, //this oddsoption.id would not consider any party - eg Draw option
    bidsobject: "", //this is the id of the bids collection object where bids for this subevent are saved
  };
  const docRef = await addDoc(collection(db, "subevents"), subObj);
  var bidsCollectionObj = {
    admin: store.userid,
    masterevent: store.masterevent,
    parentevent: props.parentevent,
    subevent: docRef.id,
    bids: {},
    winningbid: {},
    bidsopen: true, //if this is false no bids would be accepted
  };
  const bidRef = await addDoc(collection(db, "bids"), bidsCollectionObj);
  //await addDoc(collection(db, "subevents"), subObj);
  //store.clearMasterEvent(); //this is to close the masterevent component
  const washingtonRef = doc(db, "subevents", docRef.id);
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    bidsobject: bidRef.id,
  });
  emit("subeventsubmitted", true);
};
const clearNeutral = (id) => {
  const index = neutraloptions.value.indexOf(id);
  if (index > -1) {
    // only splice array when item is found
    neutraloptions.value.splice(index, 1); // 2nd parameter means remove one item only
  }
};
const addNeutral = (id) => {
  neutraloptions.value.push(id);
};
const setParty = (name, party) => {
  if (party == "party_1") {
    party_1.value = party_1.value + ", " + name;
    party_1.value = party_1.value.trim();
  }
  if (party == "party_2") {
    party_2.value = party_2.value + ", " + name;
    party_2.value = party_2.value.trim();
  }
  removeFirstAndLastComma();
};
const removeFirstAndLastComma = () => {
  if (party_1.value.startsWith(",")) {
    party_1.value = party_1.value.substring(1);
  }
  if (party_2.value.startsWith(",")) {
    party_2.value = party_2.value.substring(1);
  }

  // Remove last comma
  if (party_1.value.endsWith(",")) {
    party_1.value = party_1.value.substring(0, party_1.value.length - 1);
  }
  if (party_2.value.endsWith(",")) {
    party_2.value = party_2.value.substring(0, party_2.value.length - 1);
  }
};
</script>
<style scoped>
.container {
  font-size: 14px;
  overflow: visible; /* Allow overflow */
}
.smalllist {
  padding: 2px;
  font-size: 12px;
}
.form-control {
  font-size: 12px;
}
.dropdown-menu {
  position: absolute;
  will-change: transform;
}
.double-height-container {
  height: 200vh; /* 200% of viewport height */
}
</style>
