<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"
              ><i class="bi bi-house" @click="toNewEvent()"></i
            ></a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown_1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Events
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown_11">
              <li><a class="dropdown-item" @click="toNewEvent()">Master</a></li>
              <li><a class="dropdown-item" href="#">SubEvent</a></li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown_2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Preview
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown_2">
              <li>
                <a class="dropdown-item">Check&Publish</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown_2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Results
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown_2">
              <li>
                <a class="dropdown-item" @click="toSetResults()">SetResults</a>
              </li>
              <li>
                <a class="dropdown-item" @click="toPrizes()">Prizes</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown_2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Teams
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown_2">
              <li>
                <a class="dropdown-item" @click="toTeams()">ManageTeams</a>
              </li>
              <li>
                <a class="dropdown-item" @click="to1v3()">1v3</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown_2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Messages
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown_2">
              <li>
                <a class="dropdown-item" @click="toMessages()">NotifyUsers</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown_2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Settings
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown_2">
              <li>
                <a class="dropdown-item" @click="toSettings()">General</a>
              </li>
            </ul>
          </li>
        </ul>
        <button class="btn btn-sm btn-primary mx-2" @click="updateBuildHash()">
          Build - {{ hash }}
        </button>
        <button class="btn btn-sm btn-secondary" @click="logout()">
          Logout
        </button>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { auth } from "@/firebase/config";
import { useStore } from "@/store/index";
import { useSubevents } from "../../store/subevents";
import { signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { watchEffect, onMounted, onBeforeUnmount, ref } from "vue";
const { customAlphabet } = require("nanoid");
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 4);
const hash = ref("");
//nanoid() //=> "GDWP"
var build_subscribe = () => {};
onMounted(() => {
  build_subscribe = onSnapshot(
    doc(db, "buildhash", "lNZRfY4I36kZ3Qy7W6s3"),
    (docy) => {
      //console.log("Current data: ", doc.data());
      hash.value = docy.data().hash;
    }
  );
});
onBeforeUnmount(() => {
  build_subscribe();
});
var unsubscribe = () => {};
var unsub = () => {};
const router = useRouter();
const store = useStore();
const se = useSubevents();
watchEffect(() => {
  if (store.masterevent) {
    //console.log("Hello From Admin Nav=>", store.masterevent);
    //setup listner to all subevents in this masterevent and populate the store.
    //console.log("fetch data for MasterEvent =>", masterevent.value);
    const q = query(
      collection(db, "subevents"),
      where("masterevent", "==", store.masterevent)
    );
    unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((docy) => {
        if (docy.data().active) {
          //add only active events
          cities.push({ id: docy.id, ...docy.data() });
        }
      });

      se.setSubevents(cities);
    });
    unsub = onSnapshot(doc(db, "masterevents", store.masterevent), (docy) => {
      //console.log("Current data: ", doc.data());
      store.setMasterData({ id: docy.id, ...docy.data() });
    });
  } else {
    //console.log("ADMIN NAV => remove listner to subevents");
    unsubscribe();
    unsub();
    se.clearSubevents();
    store.clearMasterEvent();
  }
});
const toNewEvent = () => {
  router.push("/event");
};
const toSettings = () => {
  router.push("/settings");
};
const toSetResults = () => {
  router.push("/setresults");
};
const toTeams = () => {
  router.push("/teams");
};
const to1v3 = () => {
  router.push("/1v3");
};
const toMessages = () => {
  router.push("/messages");
};
const toPrizes = () => {
  router.push("/prizes");
};
const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      store.clearUser();
      se.clearSubevents();
      store.clearMasterEvent();
      router.push("/admin");
    })
    .catch((error) => {
      // An error happened.
    });
  //router.push("/");
};
const updateBuildHash = async () => {
  const washingtonRef = doc(db, "buildhash", "lNZRfY4I36kZ3Qy7W6s3");

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    hash: nanoid(),
  });
};
</script>
