<template>
  <div class="container boder border-success" style="overflow: hidden">
    <SubEventList
      :parentevent="props.parentevent"
      :subeventsort="props.subeventsort"
    ></SubEventList>
  </div>
</template>
<script setup>
import SubEventList from "./SubEventList.vue";
import { defineProps } from "vue";
const props = defineProps(["parentevent", "subeventsort"]);
</script>
