import { defineStore } from "pinia";

export const useStore = defineStore("counter", {
  state: () => ({
    userid: "",
    isadmin: false,
    adminsettings: {},
    masterevent: "",
    mastername: "",
    masterdata: {},
    eventdates: [],
    expandparent: "",
    subeventsforprize: [],
  }),

  actions: {
    setUser(id) {
      this.userid = id;
    },
    setAdmin() {
      this.isadmin = true;
    },
    clearUser() {
      this.userid = "";
      this.isadmin = false;
    },
    setMasterEvent(id, name) {
      this.masterevent = id;
      this.mastername = name;
    },
    clearMasterEvent() {
      this.masterevent = "";
      this.mastername = "";
      this.masterdata = {};
    },
    setMasterData(obj) {
      this.masterdata = { ...obj };
    },
    addEventDate(str) {
      if (str.length && !this.eventdates.includes(str))
        this.eventdates.push(str);
    },
    clearEventDates() {
      this.eventdates = [];
    },
    expandThisParent(id) {
      if (this.expandparent != id) {
        this.expandparent = id;
      } else {
        this.expandparent = "";
      }
    },
    addForPrize(id) {
      if (!this.subeventsforprize.includes(id)) {
        this.subeventsforprize.push(id);
      }
    },
    removeFromPrize(id) {
      this.subeventsforprize = this.subeventsforprize.filter(
        (item) => item !== id
      );
    },
    resetPrizeArr() {
      this.subeventsforprize = [];
    },
  },
});
