<template>
  <div class="d-flex justify-content-around mt-5">
    <div v-for="master in masterevents" :key="master.id">
      <button
        class="btn pulsating-btn d-flex align-items-center justify-content-center"
        :class="getBtnClass(master.eventicon)"
        @click="selectMaster(master.id, master.name)"
      >
        <h6 class="h6">{{ master.name }}</h6>

        <MasterIcon
          v-if="master.eventicon"
          :icon="master.eventicon"
          class="mx-1"
        ></MasterIcon>
      </button>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { useBids } from "../../store/bids";
import { db, auth } from "../../firebase/config";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { ref, onMounted, onBeforeUnmount } from "vue";
import MasterIcon from "./MasterIcon.vue";
const store = useStore();
const bids = useBids();
const masterevents = ref([]);
const mastereventbids = ref([]);
var unsubscribe = () => {};
onBeforeUnmount(() => {
  unsubscribe();
  //console.log("unsubscribing event list...");
});
onMounted(() => {
  const q = query(collection(db, "masterevents"));
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      if (docy.data().active) {
        // add only active events
        cities.push({ id: docy.id, ...docy.data() });
      }
    });
    //console.log("Current cities in CA: ", cities.join(", "));
    masterevents.value = cities;
  });
});
function getFirst8Chars(str) {
  return str.substring(0, 8);
}
const selectMaster = async (id, name) => {
  setTimeStamp();
  if (store.masterevent != id) {
    store.setMasterEvent(id, name);
    const q = query(collection(db, "bids"), where("masterevent", "==", id));
    ////get a list of all subevents that this user has bid for - this is used for tick mark///////
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docy) => {
      mastereventbids.value.push({ id: docy.id, ...docy.data() });
    });

    mastereventbids.value.forEach((m) => {
      bids.addPair({ bidid: m.id, subevent: m.subevent });

      for (const property in m.bids) {
        if (property == getFirst8Chars(auth.currentUser.uid)) {
          bids.submittedBid({
            subevent: m.subevent,
            bidid: m.bids[property].bidid,
            party: m.bids[property].party,
          });
        }
        //bids of all users for this subevent
        bids.addToAllBids({
          userid: property,
          subevent: m.subevent,
          bidid: m.bids[property].bidid,
          party: m.bids[property].party,
        });
      }
    });
    //bids.setBids(mastereventbids.value);
  } else {
    store.clearMasterEvent();
    bids.clearBids();
  }
  setStoreTimeNow();
};
const setTimeStamp = async () => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(docRef, {
    timenow: serverTimestamp(),
  });
};
const setStoreTimeNow = async () => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    ///console.log("Document data:", docSnap.data());
    if (docSnap.data().timenow) {
      // console.log("Setting store timestamp here............");
      bids.setPresentTime(docSnap.data().timenow.seconds);
    }
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
const getBtnClass = (icon) => {
  var cl = "";
  if (!icon) {
    cl = "btn-primary";
  } else {
    icon = "border border-1";
  }
  return cl;
};
</script>
<style scoped>
.pulsating-btn {
  position: relative;
  animation: pulsate 1.5s infinite;
}
.h6 {
  margin-bottom: 0px;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 7px rgba(0, 123, 255, 1);
  }
  100% {
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
}
</style>
