<template>
  <div class="container">
    <AdminNav></AdminNav>
    <MasterEventList v-if="!store.masterevent"></MasterEventList>
    <div class="row mt-1" v-if="store.masterevent">
      <div class="col">
        <i class="bi bi-x-lg float-end" @click="store.clearMasterEvent()"></i>
      </div>
    </div>
    <div class="row mt-1" v-if="store.masterevent">
      <div class="col"><NewTeam></NewTeam></div>
      <div class="col"><TeamList :masterdata="masterdata"></TeamList></div>
      <!-- <div class="col">
        <PlayersList :masterdata="masterdata"></PlayersList>
      </div> -->
    </div>
  </div>
</template>
<script setup>
import AdminNav from "@/components/navbars/AdminNav.vue";
import MasterEventList from "@/components/events/MasterEventList.vue";
import NewTeam from "./NewTeam.vue";
import TeamList from "./TeamList.vue";
import PlayersList from "./PlayersList.vue";
import { watchEffect, ref, onBeforeUnmount } from "vue";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase/config";
import { useStore } from "@/store/index";
const masterdata = ref({});
const store = useStore();
var unsub = () => {};
onBeforeUnmount(() => {
  unsub();
});
watchEffect(() => {
  if (store.masterevent) {
    unsub = onSnapshot(doc(db, "masterevents", store.masterevent), (docy) => {
      masterdata.value = { id: docy.id, ...docy.data() };
      //console.log("Current data: ", doc.data());
    });
  }
});
</script>
