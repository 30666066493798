<template>
  <div class="row"></div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { messaging, db, auth } from "../../firebase/config";
import { getToken } from "firebase/messaging";
import { doc, setDoc } from "firebase/firestore";
const t = ref("");
function requestPermission() {
  console.log("Requesting permission... in my own component!!!");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      //generate token here
      getToken(messaging, {
        vapidKey:
          "BFnmaXsRmRce1flyM_rzo-2UhvKqKqC8BM5E4b09UoDDD5rAlxykKaocwpHYt0KMZ6alvdPXUyvgZRC_GjGqC9w",
      })
        .then(async (currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log("CURRENT TOKEN:", currentToken);
            t.value = currentToken;
            const cityRef = doc(db, "fcmtokens", auth.currentUser.uid);
            setDoc(cityRef, { token: currentToken }, { merge: true });
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    } else if (permission === "denied") {
      alert(
        "Permission denied. Notification permission required for app to work."
      );
    }
  });
}
onMounted(() => {
  requestPermission();
});
</script>
