<template>
  <ul class="list-group smalltext" v-if="subevents.length">
    <li
      class="list-group-item smalllist"
      v-for="e in sorted_subevents"
      :key="e.id"
    >
      <div class="row mt-1">
        <div class="col-2">
          <span class="mx-1">
            <i
              class="bi bi-dash"
              v-if="selectedSubEvent == e.id"
              @click="updateSno(e.id, e.sno, 'minus')"
            ></i>
            {{ e.sno }}
            <i
              class="bi bi-plus"
              v-if="selectedSubEvent == e.id"
              @click="updateSno(e.id, e.sno, 'plus')"
            ></i>
          </span>
          <span
            @click="selectSubEvent(e.id)"
            class="border rounded padding3px mt-1"
            :class="specialClass(e.parentevent)"
          >
            {{ e.name }}
            <i class="bi bi-check-lg" style="color: green" v-if="e.active"></i>
            <i class="bi bi-x-lg" style="color: red" v-if="!e.active"></i>
          </span>
          <span
            class="badge rounded-pill bg-warning text-dark float-end"
            @click="setAction('edit')"
            v-if="selectedSubEvent == e.id"
            ><i class="bi bi-pen mx-2 lgicon"></i
          ></span>

          <i
            class="bi bi-arrows float-end"
            style="color: blue"
            v-if="e.neutraloptions.length == Object.keys(e.oddsoptions).length"
          ></i>
        </div>

        <div class="col-4 position-relative">
          <!-- <i
            class="bi bi-star-fill position-absolute top-0 start-0 translate-middle"
          ></i> -->
          <i
            class="bi bi-caret-down position-absolute top-0 start-0 translate-middle"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton1"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              class="dropdown-item smalllist"
              v-for="t in sorted_icons"
              :key="t.name"
              @click="updateIcon(t.icon, 'party_1', e.id)"
            >
              {{ t.team }}
            </li>
          </ul>

          <i
            class="bi bi-caret-down position-absolute top-0 end-0 translate-middle"
            type="icon"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton1"
            aria-expanded="false"
          ></i>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              class="dropdown-item smalllist"
              v-for="t in sorted_icons"
              :key="t.name"
              @click="updateIcon(t.icon, 'party_2', e.id)"
            >
              {{ t.team }}
            </li>
          </ul>

          <!-- Top-right icon -->
          <!-- <i class="bi bi-heart-fill position-absolute top-0 end-0"></i> -->
          <div class="row" @click="selectSubEvent(e.id)">
            <div class="col border rounded">
              <TinyIcon :icon="e.logo_1"></TinyIcon> {{ e.party_1 }}
            </div>
            <div class="col border rounded">
              <TinyIcon :icon="e.logo_2"></TinyIcon>
              {{ e.party_2 }}
            </div>
          </div>
          <!-- <span @click="selectSubEvent(e.id)"
            >{{ e.party_1 }} v/s {{ e.party_2 }}</span
          > -->
          <i
            v-if="action != 'subevent' && selectedSubEvent == e.id"
            class="badge rounded-pill bg-success float-center m-2"
            @click="setAction('subevent')"
          >
            Subevent</i
          >
        </div>
        <div class="col">
          <ul class="list-group list-group-horizontal">
            <li
              class="list-group-item smalllist"
              v-for="o in sorted_odds(e.oddsoptions)"
              :key="o.id"
            >
              {{ o.name }}
            </li>
          </ul>
        </div>
        <div class="col">
          <!-- {{ fetchNumberOfSubEvents(e.id) }}{{ numberofsubevents }} -->
          <div>
            <!-- {{ checkCount(e.id, e.name) }} subEvents: {{ subeventcount }}, -->
            <i
              class="bi bi-caret-down mx-1"
              v-if="e.subeventsort == 'desc' || !e.subeventsort"
              @click="setSubEventOrder(e.id, 'asc')"
            ></i
            ><i
              class="bi bi-caret-up mx-1"
              v-if="e.subeventsort == 'asc'"
              @click="setSubEventOrder(e.id, 'desc')"
            ></i>
          </div>
        </div>
        <div class="col-2">{{ unixToDatetimeLocal(e.lastbid) }}</div>
        <div class="col">
          <button
            class="btn btn-sm btn-success tinybutton"
            v-if="e.acceptbids"
            @click="toggleAcceptBids(e.id, e.acceptbids, e.bidsobject)"
          >
            Open
          </button>
          <button
            class="btn btn-sm btn-danger tinybutton"
            v-if="!e.acceptbids"
            @click="toggleAcceptBids(e.id, e.acceptbids, e.bidsobject)"
          >
            BidsClosed
          </button>
        </div>

        <div class="col">
          <!-- <span
            class="badge rounded-pill bg-success float-end"
            @click="toggleActive(e.id, e.active)"
            v-if="e.active && selectedSubEvent == e.id"
            >Active</span
          >
          <span
            class="badge rounded-pill bg-danger float-end"
            @click="toggleActive(e.id, e.active)"
            v-if="!e.active && selectedSubEvent == e.id"
            >Deactivated</span
          > -->
          <span
            class="badge rounded-pill bg-success float-end"
            @click="toggleActive(e.id, e.active)"
            v-if="e.active"
            >Active</span
          >
          <span
            class="badge rounded-pill bg-danger float-end"
            @click="toggleActive(e.id, e.active)"
            v-if="!e.active"
            >Deactivated</span
          >
        </div>
        <div class="col">
          <div class="float-end" v-if="selectedSubEvent == e.id">
            <!-- <i class="bi bi-pen mx-2 lgicon" @click="setAction('edit')"></i> -->
            <i
              class="bi bi-x-circle mx-2"
              v-if="action == 'subevent'"
              @click="setAction('')"
            ></i>
            <!-- <i
              v-if="action != 'subevent' && selectedSubEvent == e.id"
              class="bi bi-plus-circle mx-2 btn btn-sm btn-primary"
              @click="setAction('subevent')"
            ></i> -->
            <i
              class="bi bi-trash float-end lgicon text-danger"
              v-if="action != 'delete'"
              @click="setAction('delete')"
            ></i>
          </div>
        </div>
      </div>
      <DeleteSubeventAndBids
        v-if="selectedSubEvent == e.id && action == 'delete'"
        @deleteaction="setAction('')"
        :subevent="e.id"
      ></DeleteSubeventAndBids>
      <EditSubevent
        v-if="selectedSubEvent == e.id && action == 'edit'"
        :subevent="e"
        @subeventupdated="selectSubEvent(e.id)"
      ></EditSubevent>
      <NewSubEvent
        v-if="selectedSubEvent == e.id && action == 'subevent'"
        @subeventsubmitted="selectSubEvent(e.id)"
        :oddsoptions="e.oddsoptions"
        :parentevent="e.id"
        :parentname="e.name"
        :neutraloptions="e.neutraloptions"
        :eventdate="e.eventdate"
        :firstbid="e.firstbid"
        :lastbid="e.lastbid"
      ></NewSubEvent>

      <SubEventTemplate
        :parentevent="e.id"
        :subeventsort="e.subeventsort"
      ></SubEventTemplate>
    </li>
  </ul>
</template>
<script setup>
import { defineProps, ref, onMounted, onBeforeUnmount, computed } from "vue";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getCountFromServer,
  updateDoc,
  getDocs,
  limit,
} from "firebase/firestore";
import EditSubevent from "./EditSubevent.vue";
import NewSubEvent from "./NewSubEvent.vue";
import SubEventTemplate from "./SubEventTemplate.vue";
import TinyIcon from "./TinyIcon.vue";
import DeleteSubeventAndBids from "./DeleteSubeventAndBids.vue";
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
const props = defineProps(["parentevent", "subeventsort"]);
const selectedSubEvent = ref("");
const action = ref("");
const store = useStore();
const bids = ref([]);
const iconfiles = ref([]);
const populateIconFiles = () => {
  for (const property in store.masterdata.teams) {
    //console.log(`${property}: ${object[property]}`);
    if (
      !iconfiles.value.includes({
        team: store.masterdata.teams[property].name,
        icon: store.masterdata.teams[property].icon,
      })
    ) {
      iconfiles.value.push({
        team: store.masterdata.teams[property].name,
        icon: store.masterdata.teams[property].icon,
      });
    }
  }
  return;
};
const sorted_icons = computed(() => {
  var arr = iconfiles.value;

  let sorted_arr = sortByField(arr, "team");
  return sorted_arr;
});
const updateIcon = async (f, p, id) => {
  const washingtonRef = doc(db, "subevents", id);
  if (p == "party_1") {
    await updateDoc(washingtonRef, {
      logo_1: f,
    });
  }
  if (p == "party_2") {
    await updateDoc(washingtonRef, {
      logo_2: f,
    });
  }
};
// function sortByField_icons(arr, field) {
//   // Use slice() to create a new array and avoid mutating the original array
//   return arr.slice().sort((a, b) => {
//     // Compare the values of the specified field
//     if (a[field] > b[field]) {
//       return 1;
//     } else if (a[field] < b[field]) {
//       return -1;
//     } else {
//       return 0;
//     }
//   });
// }
var unsub_bids = () => {};
function unixToDatetimeLocal(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} @ ${hours}:${minutes}`;
}
const setSubEventOrder = async (event, order) => {
  const washingtonRef = doc(db, "subevents", event);
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    subeventsort: order,
  });
};
const sorted_subevents = computed(() => {
  var arr = [];
  for (const property in subevents.value) {
    arr.push({
      id: property,
      ...subevents.value[property],
    });
  }
  let sorted_arr = sortByField_events(arr, "sno");
  return sorted_arr;
});
function sortByField_events(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  if (props.subeventsort == "asc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] > b[field]) {
        return 1;
      } else if (a[field] < b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  if (props.subeventsort == "desc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const sorted_odds = (odds) => {
  var arr = [];
  for (const property in odds) {
    arr.push({
      id: property,
      ...odds[property],
    });
  }
  let sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
};

const setAction = (str) => {
  //console.log("Setting Action =>", str);
  if (action.value != str) {
    action.value = str;
  } else {
    action.value = "";
  }
};
const selectSubEvent = (id) => {
  if (selectedSubEvent.value != id) {
    selectedSubEvent.value = id;
  } else {
    selectedSubEvent.value = "";
  }
  action.value = "";
};
const subevents = ref([]);
var unsubscribe = () => {};
onBeforeUnmount(() => {
  unsubscribe();
  unsub_bids();
});
onMounted(() => {
  populateIconFiles();
  const q = query(
    collection(db, "subevents"),
    where("parentevent", "==", props.parentevent)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    subevents.value = cities;
  });
  ////////////////setting up listner to all bid collection objects to show how many bids
});
const updateSno = async (id, sno, action) => {
  if (!sno) {
    sno = 0;
  }
  var new_sno = parseInt(sno);
  if (action == "plus") {
    new_sno++;
  }
  if (action == "minus") {
    new_sno--;
  }

  const washingtonRef = doc(db, "subevents", id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    sno: new_sno,
  });
};
const toggleActive = async (id, active, bidsobject) => {
  //console.log("toggleActive=>", masterData.value.id);

  const washingtonRef = doc(db, "subevents", id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    active: !active,
  });
};
const toggleAcceptBids = async (id, accept, bidsobject) => {
  //console.log("toggleActive=>", masterData.value.id);
  console.log(bidsobject);
  const washingtonRef = doc(db, "subevents", id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    acceptbids: !accept,
  });
  const bidRef = doc(db, "bids", bidsobject);

  // Set the "capital" field of the city 'DC'
  await updateDoc(bidRef, {
    bidsopen: !accept,
  });
};
const specialClass = (parent) => {
  var str = "";
  if (parent == store.masterevent) {
    str = "bg-info border rounded";
  }
  return str;
};
</script>
<style scoped>
.tinybutton {
  padding: 1px;
  font-size: 10px;
}
.padding3px {
  padding: 3px;
}
.smalltext {
  font-size: 12px;
}
.smalllist {
  padding: 2px;
  font-size: 12px;
  position: relative;
}
.btn-glow {
  transition: box-shadow 0.3s ease-in-out;
}

.btn-glow:hover {
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.6);
}
.lesspadding {
  padding: 2px;
}
.lgicon {
  font-size: 14px;
}
.dropdown-menu {
  z-index: 1050;
  position: relative;
}
.dropdown-item {
  z-index: 1050;
  position: relative;
}
</style>
