<template>
  <div class="container">
    <AdminNav v-if="store.isadmin"></AdminNav>
    <!-- {{ store }} -->
    <div class="row mt-1" v-if="!showNewEventForm">
      <div class="col">
        <i class="bi bi-plus-circle" @click="toggleShowForm()"></i>
      </div>
    </div>
    <div class="row mt-1" v-if="showNewEventForm">
      <div class="float-end">
        <i class="bi bi-x-circle float-end" @click="toggleShowForm()"></i>
      </div>

      <div class="col">
        Master Event:
        <input
          type="text"
          class="form-control"
          placeholder="Name..."
          v-model.trim="name"
        />
      </div>

      <div class="col">
        <div class="row">
          <div class="col">
            New Odd:
            <input
              type="text"
              class="form-control"
              v-model.trim="option"
              @keyup.enter="addLocalOdd"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <ul class="list-group">
          <li
            class="list-group-item smalllist"
            v-for="o in sorted_odds"
            :key="o"
          >
            <div class="row">
              <div class="col">
                <i
                  class="bi bi-dash"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'minus')"
                ></i>
                {{ o.sno }}
                <i
                  class="bi bi-plus"
                  v-if="selectedOption == o.id"
                  @click="updateSno(o.id, o.sno, 'plus')"
                ></i>
              </div>
              <div class="col">
                <span @click="selectThis(o.id)">{{ o.name }}</span>
                <i
                  class="fa fa-arrows-h mx-2 float-end"
                  style="font-size: 18px; color: blue"
                  v-if="isNeutral(o.id)"
                ></i>
              </div>
              <div class="col">
                <div v-if="selectedOption == o.id">
                  <span
                    class="badge rounded-pill bg-light text-dark"
                    v-if="isNeutral(o.id)"
                    @click="clearNeutral(o.id)"
                  >
                    <i
                      class="fa fa-arrows-h mx-2 float-end"
                      style="font-size: 12px"
                    ></i>
                    <i class="bi bi-x-circle"></i>
                  </span>
                  <span
                    class="badge rounded-pill bg-primary"
                    v-if="!isNeutral(o.id)"
                    @click="addNeutral(o.id)"
                  >
                    <i
                      class="fa fa-arrows-h mx-2 float-end"
                      style="font-size: 12px"
                    ></i>
                    <i class="bi bi-plus-circle"></i>
                  </span>
                  <i
                    class="bi bi-trash float-end"
                    @click="deleteOption(o.id)"
                  ></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col">
        <button
          class="btn btn-sm btn-primary"
          v-if="checkIfFieldsFilled"
          @click="addMasterEvent()"
        >
          Add New Master Event
        </button>
      </div>
    </div>
    <div v-if="!store.masterevent">
      <MasterEventList></MasterEventList>
    </div>
    <div v-if="store.masterevent">
      <MasterEvent></MasterEvent>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import MasterEventList from "./MasterEventList.vue";
import MasterEvent from "./MasterEvent.vue";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import {
  doc,
  updateDoc,
  onSnapshot,
  deleteField,
  collection,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import AdminNav from "../../components/navbars/AdminNav.vue";
const settings = ref({});
const option = ref("");
const selectedOption = ref("");
const toggleShowForm = () => {
  showNewEventForm.value = !showNewEventForm.value;
};
const name = ref("");
const showNewEventForm = ref(false);
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const checkIfFieldsFilled = computed(() => {
  var ok = true;
  if (!name.value) {
    ok = false;
  }

  return ok;
});
var unsub = () => {};
const store = useStore();
onMounted(async () => {
  const docRef = doc(db, "users", store.userid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    settings.value = { id: docSnap.id, ...docSnap.data() };
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
});
onBeforeUnmount(() => {
  unsub();
  store.clearMasterEvent();
});
const sorted_odds = computed(() => {
  var arr = [];
  for (const property in settings.value.oddsoptions) {
    arr.push({
      id: property,
      ...settings.value.oddsoptions[property],
    });
  }
  let sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const getLargestSno = () => {
  return Object.keys(settings.value.oddsoptions).length + 1;
};
const addLocalOdd = () => {
  //console.log("adding local");
  const oddObj = {
    name: option.value,
    sno: getLargestSno(),
  };
  settings.value.oddsoptions[nanoid()] = oddObj;
  option.value = "";
};
const selectThis = (id) => {
  if (selectedOption.value != id) {
    selectedOption.value = id;
  } else {
    selectedOption.value = "";
  }
};
const deleteOption = (id) => {
  //console.log("Local Delete", id);
  delete settings.value.oddsoptions[id];
};
const updateSno = async (id, sno, action) => {
  //console.log("updating sno");
  var new_sno = parseInt(sno);
  if (action == "plus") {
    new_sno++;
  }
  if (action == "minus") {
    new_sno--;
  }
  //console.log(settings.value.oddsoptions[id]);
  settings.value.oddsoptions[id].sno = new_sno;
};
const addMasterEvent = async () => {
  //console.log(eventdate.value, firstbid.value, lastbid.value);
  const masterObj = {
    admin: store.userid,
    name: name.value,
    oddsoptions: settings.value.oddsoptions,
    active: true,
    subeventsort: "desc",
    neutraloptions: settings.value.neutraloptions,
  };
  await addDoc(collection(db, "masterevents"), masterObj);
  name.value = "";
};
const isNeutral = (id) => {
  if (settings.value.neutraloptions) {
    if (settings.value.neutraloptions.includes(id)) return true;
  }
  return false;
};
const clearNeutral = (id) => {
  const index = settings.value.neutraloptions.indexOf(id);
  if (index > -1) {
    // only splice array when item is found
    settings.value.neutraloptions.splice(index, 1); // 2nd parameter means remove one item only
  }
};
const addNeutral = (id) => {
  settings.value.neutraloptions.push(id);
};
</script>
<style scoped>
.smalllist {
  padding: 2px;
  font-size: 12px;
}
</style>
