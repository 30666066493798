<template>
  <div class="row">
    <div class="col">
      <h6>Present Baseline</h6>
      <ul class="list-group">
        <li
          class="list-group-item smalltxt"
          v-for="b in store.masterdata.positionmovement"
          :key="b.u"
        >
          {{ b.rank }} {{ displayName(b.u) }}: {{ b.w }}
        </li>
      </ul>
    </div>
    <div class="col">
      <h6>Present Leaderboard</h6>
      <button class="btn btn-primary btn-sm" @click="setAsBaseline()">
        SetAsBaseline
      </button>
      <ul class="list-group mt-1">
        <li
          class="list-group-item smalltxt"
          v-for="(l, index) in sorted_leaderboard"
          :key="l.u"
        >
          {{ index + 1 }}. {{ displayName(l.u) }}: {{ l.w }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { ref, onMounted, computed } from "vue";
import { db } from "../../firebase/config";
const store = useStore();
const leaderboard = ref([]);
const displaynames = ref({});
const sort_col = ref("wins");
import { doc, getDoc, updateDoc } from "firebase/firestore";

onMounted(async () => {
  const docRef = doc(db, "leaderboards", store.masterevent);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    leaderboard.value = docSnap.data().leaderboard;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  const dispRef = doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e");
  const dispSnap = await getDoc(dispRef);
  if (dispSnap.exists()) {
    displaynames.value = { ...dispSnap.data().names };
  } else {
    console.log("Display names not found");
  }
});
const sorted_leaderboard = computed(() => {
  var arr = [];
  leaderboard.value.forEach((s) => {
    arr.push(s);
  });
  arr = arr.map((obj) => ({
    ...obj, // Keep all other properties the same
    s: parseFloat(obj.s), // this is done because 100.00 does not come at No1 in descending sort
  }));
  var sorted_arr = [];
  if (sort_col.value == "wins") {
    sorted_arr = sortByField(arr, "w");
  }
  if (sort_col.value == "sr") {
    sorted_arr = sortByField(arr, "s");
  }

  //var sorted_arr = sortByField(arr, "w");
  return sorted_arr;
});
function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}

const displayName = (id) => {
  var name = "";
  if (displaynames.value[id]) {
    name = truncateString(displaynames.value[id]);
  }
  return name;
};
function truncateString(str) {
  if (str.length > 25) {
    return str.slice(0, 22) + "...";
  }
  return str;
}
const setAsBaseline = async () => {
  const baseline = [];
  sorted_leaderboard.value.forEach((s, index) => {
    //console.log(s);
    baseline.push({ rank: index + 1, ...s });
  });
  console.log(baseline);
  const washingtonRef = doc(db, "masterevents", store.masterevent);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    positionmovement: baseline,
  });
};
</script>
<style scoped>
.smalltxt {
  font-size: 12px;
  padding: 2px;
}
</style>
