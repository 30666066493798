<template>
  <div class="container">
    <AdminNav></AdminNav>
    <div class="row">
      <div class="col">
        <div class="card mx-auto mt-3" style="width: 18rem">
          <h5 class="card-header">Push Notifications</h5>
          <div class="card-body mt-3">
            <input type="text" class="form-control" placeholder="Data" />
          </div>
          <div class="card-footer">
            <button
              class="btn btn-danger btn-sm float-start"
              @click="fetchTokens()"
            >
              FetchTokens
            </button>
            <button
              class="btn btn-primary btn-sm float-end"
              v-if="tokens.length"
              @click="notifyUsers()"
            >
              Notify {{ tokens.length }} Users
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import AdminNav from "../navbars/AdminNav.vue";
import { db, messaging } from "../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { ref } from "vue";
const tokens = ref([]);
const fetchTokens = async () => {
  tokens.value = [];
  const querySnapshot = await getDocs(collection(db, "fcmtokens"));
  querySnapshot.forEach((docy) => {
    tokens.value.push(docy.data().token);
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
  });
};
const notifyUsers = () => {
  // This registration token comes from the client FCM SDKs.

  const message = {
    data: { score: "850", time: "2:45" },
    tokens: tokens.value,
  };
  //console.log(messaging);
  messaging.sendMulticast(message).then((response) => {
    //console.log(response.successCount + " messages were sent successfully");
  });
};
</script>
