<template>
  <div class="container">
    <AdminNav v-if="store.isadmin"></AdminNav>
    <UserNav v-if="!store.isadmin"></UserNav>
    <UserHome v-if="!store.isadmin"></UserHome>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import AdminNav from "../../components/navbars/AdminNav.vue";
import UserNav from "../../components/navbars/UserNav.vue";
import UserHome from "../../components/homepages/UserHome.vue";

const store = useStore();
</script>
