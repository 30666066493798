<template>
  <div class="container">
    <AdminNav></AdminNav>
    <MasterEventList v-if="!store.masterevent"></MasterEventList>
    <div class="row mt-1" v-if="store.masterevent">
      <div class="col">
        <i class="bi bi-x-lg float-end" @click="store.clearMasterEvent()"></i>
      </div>
    </div>
    <div v-if="store.masterevent">
      <div class="row">
        <div class="row">
          <div class="col d-flex justify-content-around">
            <span
              v-for="t in team_list"
              :key="t.id"
              class="border rounded"
              @click="addGroup(t.id)"
              >{{ t.name }}</span
            >
          </div>
        </div>
        <div class="row mt-1">
          <div class="col d-flex justify-content-around">
            <span
              v-for="g in group"
              :key="g"
              class="border rounded border-primary"
              >{{ teamName(g) }}

              <ul class="list-group">
                <li
                  class="list-group-item smalllist"
                  :class="ifPlayerInFlight(p.id)"
                  v-for="p in players_list(g)"
                  :key="p.id"
                  @click="addToFlight(p.id)"
                >
                  [{{ p.id }}] {{ p.player }}
                  <span class="float-end">({{ g }})</span>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>
      PlayerID: {{ flight_robin }}
      <div class="row">
        <div class="col d-flex justify-content-around mt-1">
          <span
            v-for="gr in group_robin"
            :key="gr"
            class="border rounded smalllist border-success"
          >
            {{ teamName(gr[0]) }} v
            {{ teamName(gr[1]) }}
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="fr in thisMatchGames(gr)"
                :key="fr"
              ></li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import AdminNav from "@/components/navbars/AdminNav.vue";
import MasterEventList from "@/components/events/MasterEventList.vue";

import { watchEffect, ref, onBeforeUnmount, computed } from "vue";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase/config";
import { useStore } from "@/store/index";
const masterdata = ref({});

const store = useStore();
const group = ref([]);
const pool = ref([]);
const flight = ref([]);
var unsub = () => {};
const teamName = (id) => {
  var name = "";
  for (const property in masterdata.value.teams) {
    //console.log(`${property}: ${object[property]}`);
    if (property == id) {
      name = masterdata.value.teams[property].name;
    }
  }
  return name;
};
onBeforeUnmount(() => {
  unsub();
});
watchEffect(() => {
  if (store.masterevent) {
    unsub = onSnapshot(doc(db, "masterevents", store.masterevent), (docy) => {
      masterdata.value = { id: docy.id, ...docy.data() };
    });
  }
});
const team_list = computed(() => {
  const arr = [];
  for (const property in masterdata.value.teams) {
    //console.log(`${property}: ${object[property]}`);
    arr.push({ id: property, ...masterdata.value.teams[property] });
  }
  var sorted_arr = sortByField(arr, "name");
  return sorted_arr;
});
function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
function sortByField_asc(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const addGroup = (id) => {
  if (!group.value.includes(id)) {
    group.value.push(id);
  } else {
    group.value = group.value.filter((item) => item !== id);
  }
};
const players_list = (id) => {
  var list = [];
  for (const property in masterdata.value.teams[id].players) {
    list.push({
      id: property,
      ...masterdata.value.teams[id].players[property],
    });
  }
  var sorted_list = sortByField_asc(list, "player");
  //   sorted_list.forEach((element) => {
  //     pnames.value[element.id] = element.name;
  //   });
  return sorted_list;
};
const group_robin = computed(() => {
  let matches = [];
  for (let i = 0; i < group.value.length; i++) {
    for (let j = i + 1; j < group.value.length; j++) {
      matches.push([group.value[i], group.value[j]]);
    }
  }
  return matches;
});
const ifPlayerInFlight = (id) => {
  var str = "";
  if (flight.value.includes(id)) {
    str = "bg-info";
  }
  return str;
};
const addToFlight = (id) => {
  if (!flight.value.includes(id) && flight.value.length < 4) {
    flight.value.push(id);
  } else {
    flight.value = flight.value.filter((item) => item !== id);
  }
};
const flight_robin = computed(() => {
  let matches = [];
  for (let i = 0; i < flight.value.length; i++) {
    for (let j = i + 1; j < flight.value.length; j++) {
      matches.push([flight.value[i], flight.value[j]]);
    }
  }
  return matches;
});
const allplayers = computed(() => {
  const arr = [];
  for (const property in masterdata.value.teams) {
    //console.log(`${property}: ${object[property]}`);
    //arr.push({ id: property, ...masterdata.value.teams[property] });
    for (const playerid in masterdata.value.teams[property].players) {
      arr.push({
        id: playerid,
        player: masterdata.value.teams[property].players[playerid].player,
        team: property,
      });
    }
  }
  return arr;
});
const playerName = (id) => {
  var name = "";
  allplayers.value.forEach((p) => {
    if (p.id == id) {
      name = p.player;
    }
  });
  return name;
};
const thisMatchGames = (match) => {
  var player_1 = false;
  var player_2 = false;
  console.log(match);
  var arr = [];
  //   flight_robin.value.forEach((p) => {});
};
</script>
<style scoped>
.smalllist {
  font-size: 10px;
  padding: 2px;
}
</style>
