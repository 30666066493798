<template>
  <div class="container">
    <AdminNav></AdminNav>
    <MasterEventList v-if="!store.masterevent"></MasterEventList>
    <div class="row mt-1" v-if="store.masterevent">
      <div class="col">
        <i class="bi bi-x-lg float-end" @click="store.clearMasterEvent()"></i>
      </div>
    </div>
    <div class="row mt-1" v-if="store.masterevent">
      <div class="col">
        <i class="bi bi-x-lg float-end" @click="clearSelection()"></i>
        Event Date:
        <span
          class="badge rounded-pill bg-primary mx-1 mb-1"
          v-for="d in dateList"
          :key="d"
          >{{ d }}
        </span>
        <input
          type="date"
          class="form-control mb-1"
          placeholder="Date"
          v-model.trim="eventdate"
        />

        <SubeventList :parentevent="store.masterevent"></SubeventList>
      </div>
      <div class="col"><CalculatePrize></CalculatePrize></div>
    </div>
  </div>
</template>
<script setup>
import AdminNav from "@/components/navbars/AdminNav.vue";
import MasterEventList from "@/components/events/MasterEventList.vue";
import SubeventList from "./SubeventList.vue";
import CalculatePrize from "./CalculatePrize.vue";
import { useStore } from "@/store/index";
import { ref, watchEffect } from "vue";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
const store = useStore();
const eventdate = ref("");
const dateList = ref([]);
const clearSelection = () => {
  eventdate.value = "";
  dateList.value = [];
};
watchEffect(async () => {
  if (eventdate.value) {
    if (!dateList.value.includes(eventdate.value))
      dateList.value.push(eventdate.value);
    const q = query(
      collection(db, "subevents"),
      where("masterevent", "==", store.masterevent),
      where("eventdate", "==", eventdate.value)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docy) => {
      store.addForPrize(docy.id);
    });
  } else {
    store.resetPrizeArr();
  }
});
const addToList = (id) => {
  store.addForPrize(id);
};
</script>
