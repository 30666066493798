<template>
  <div class="container mx-2 border rounded mb-2 mt-2">
    <div class="row d-flex justify-content-around">
      <div class="col text-center">
        <span
          class="badge rounded-pill"
          @click="selectOption('settings')"
          :class="getBtnClass('settings')"
          >Settings</span
        >
      </div>
      <div class="col text-center">
        <span
          class="badge rounded-pill"
          @click="selectOption('contact')"
          :class="getBtnClass('contact')"
          >Contact</span
        >
      </div>

      <!-- <div class="col" @click="selectOption('rules')">Rules</div> -->
      <!-- <div class="col" @click="selectOption('contact')">Contact</div> -->
    </div>
    <div class="row border rounded bg-warning" v-if="error">
      {{ error }}
    </div>
    <div v-if="option == 'settings'">
      <div class="row">
        <div class="col">
          <span v-if="!toggle">{{ name }}</span>
          <span v-if="toggle">
            <!-- <input type="text" class="form-control" v-model.trim="name" /> -->
            <div class="input-group mb-3 mt-3">
              <input
                type="text"
                class="form-control rounded"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                v-model.trim="name"
              />
              <button
                class="btn btn-sm btn-primary rounded"
                type="button"
                id="button-addon2"
                @click="updateName()"
              >
                Update
              </button>
              <br />
              <span class="text-muted mt-1">
                <!-- An unparliamentary display name would get you banned! -->
                Nothing unparliamentary, please, thank you :)
              </span>
            </div>
          </span>
        </div>

        <div class="col-1">
          <i
            class="bi bi-pen float-end glow-primary"
            v-if="!toggle"
            @click="toggle = !toggle"
          ></i>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col">
          <i class="bi bi-check-lg text-success float-start"></i> = correct
          choice
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col">
          <i class="bi bi-check-lg float-start"></i> = choice submitted
        </div>
      </div> -->
      <div class="row">
        <div class="col smalltxt">
          App version:
          <span class="text-muted">{{ version }}</span>
        </div>
      </div>
    </div>
    <div v-if="option == 'contact'">
      <div class="row mb-3 mt-3">
        <div class="col text-center">
          <span class="">
            <a href="mailto:contact@senohe.com ">contact@senohe.com</a></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watchEffect } from "vue";
import { auth, db } from "../../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { version } from "@/version";
const error = ref("");
const displaynames = ref({});
const name = ref("");
const toggle = ref(false);
const option = ref("settings");
watchEffect(() => {
  if (name.value) {
    name.value = name.value.replace(/[^a-zA-Z0-9._\- ]/g, "");
  }
});
const selectOption = (str) => {
  option.value = str;
  toggle.value = false;
};
const getBtnClass = (str) => {
  var return_class = "bg-secondary";
  if (str == option.value) {
    return_class = "bg-primary";
  }
  return return_class;
};
onMounted(() => {
  getDisplayNames();
  setName();
});
const getDisplayNames = async () => {
  const docRef = doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    displaynames.value = { ...docSnap.data().names };
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  setName();
};
function getFirst8Chars(str) {
  return str.substring(0, 8);
}
const setName = () => {
  error.value = "";
  name.value = displaynames.value[getFirst8Chars(auth.currentUser.uid)];
};
const updateName = async () => {
  checkIfDisplayNameExists();
  const docRef = doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e");
  if (!error.value) {
    await updateDoc(docRef, {
      ["names." + getFirst8Chars(auth.currentUser.uid)]: name.value,
    });
  }

  toggle.value = false;

  getDisplayNames();
};
const checkIfDisplayNameExists = () => {
  for (const property in displaynames.value) {
    if (
      displaynames.value[property] == name.value &&
      property != getFirst8Chars(auth.currentUser.uid)
    ) {
      error.value = "This name already exists!";
    }
  }
  if (!name.value.length) {
    error.value = "Name cannot be blank!";
  }
};
</script>
<style scoped>
.glow-primary {
  color: var(--bs-primary);
  filter: drop-shadow(0 0 10px var(--bs-primary));
}
.smalltxt {
  font-size: 12px;
}
.text-muted {
  font-size: 12px;
}
</style>
