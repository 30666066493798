<template>
  <span v-if="bids.id">
    {{ Object.keys(bids.bids).length }}
  </span>
  <span v-if="bids.id"
    ><div class="dropdown tinytxt">
      <button
        class="btn btn-secondary dropdown-toggle tinytxt"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Bids
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li class="dropdown-item tinytxt" v-for="s in sorted_bids" :key="s.u">
          [{{ s.u }}] {{ displayName(s.u) }}
          <span v-if="s.timestamp"> {{ humanTime(s.timestamp) }}</span>
        </li>
      </ul>
    </div>
  </span>
</template>
<script setup>
import { defineProps, onMounted, onBeforeUnmount, ref, computed } from "vue";
const dayjs = require("dayjs");
import { db } from "../../firebase/config";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  arrayRemove,
} from "firebase/firestore";
const props = defineProps(["subevent"]);
const bids = ref({ id: null });
const displaynames = ref({});
var unsub_bids = () => {};
var unsub = () => {};
onBeforeUnmount(() => {
  unsub_bids();
});
onMounted(() => {
  const k = query(
    collection(db, "bids"),
    where("subevent", "==", props.subevent)
  );
  unsub_bids = onSnapshot(k, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    bids.value = cities[0];
  });
  getDisplayNames();
});
const sorted_bids = computed(() => {
  var arr = [];
  console.log("computing->", Object.keys(bids.value.bids).length);
  if (Object.keys(bids.value.bids).length) {
    for (const property in bids.value.bids) {
      //console.log(`${property}: ${object[property]}`);
      arr.push({ u: property, ...bids.value.bids[property] });
    }
  }
  var sorted_arr = sortByTimestampDesc(arr);
  return sorted_arr;
});
function sortByTimestampDesc(arr) {
  return arr.sort((a, b) => {
    if (!a.timestamp || !b.timestamp) {
      return !a.timestamp ? 1 : -1;
    }
    return b.timestamp.seconds - a.timestamp.seconds;
  });
}
const humanTime = (timestamp) => {
  const readableDate = dayjs(timestamp.seconds * 1000).format(
    "DD-MMM HH:mm:ss"
  );
  return readableDate;
};
const getDisplayNames = async () => {
  unsub = onSnapshot(
    doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e"),
    (docy) => {
      //console.log("Current data: ", doc.data());
      displaynames.value = { ...docy.data().names };
    }
  );
};
const displayName = (id) => {
  var name = "";
  if (displaynames.value[id]) {
    name = displaynames.value[id];
  }
  return name;
};
</script>
<style scoped>
.tinytxt {
  font-size: 10px;
  padding: 2px;
}
</style>
