<template>
  <div class="smalltxt">
    <input type="file" @change="onFileChange" />
    <button @click="uploadFile" :disabled="!selectedFile">Upload</button>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { storage, db } from "../../firebase/config";
import { useStore } from "../../store/index";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const props = defineProps(["masterevent"]);
const selectedFile = ref(null);
const store = useStore();
const emit = defineEmits(["iconuploaddone"]);
const onFileChange = (event) => {
  selectedFile.value = event.target.files[0];
};

const uploadFile = async () => {
  if (!selectedFile.value) return;

  const fileName =
    nanoid() +
    selectedFile.value.name.slice(selectedFile.value.name.lastIndexOf("."));
  const storageReference = storageRef(storage, `images/${fileName}`);

  try {
    await uploadBytes(storageReference, selectedFile.value);
    //alert("File uploaded successfully");
    const washingtonRef = doc(db, "masterevents", store.masterevent);

    await updateDoc(washingtonRef, {
      eventicon: fileName,
    });
  } catch (error) {
    console.error("Error uploading file:", error);
    //alert("Error uploading file");
  }

  selectedFile.value = null;
  emit("iconuploaddone", true);
};
</script>
<style scoped>
.smalltxt {
  font-size: 14px;
}
</style>
