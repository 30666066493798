<template>
  <div class="row" v-if="!team">
    <div class="col">
      <ul class="list-group">
        <li
          class="list-group-item"
          v-for="(t, index) in sorted_teams"
          :key="t.id"
        >
          <div class="row">
            <div class="col" @click="selectTeam(t.id)">
              {{ index + 1 }}. {{ t.name }}
            </div>
            <div class="col" v-if="t.icon">
              <TeamIcon :icon="t.icon" :team="t.id"></TeamIcon>
            </div>
            <div class="col" v-if="!t.icon">
              <i class="bi bi-image float-end" @click="iconTeam(t.id)"></i>
            </div>
          </div>
          <div class="row" v-if="iconteam == t.id">
            <IconUpload
              :team="iconteam"
              @iconuploaddone="iconTeam(t.id)"
            ></IconUpload>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="team">
    <div class="row">
      <div class="col text-center">
        <h3>{{ getTeamName(team) }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input type="file" ref="file" @change="handleFileUpload" />
      </div>
      <div class="col">
        <i class="bi bi-x-lg float-end" @click="selectTeam(team)"></i>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder="Player..."
          v-model.trim="player"
          @keyup.enter="addPlayer()"
        />
      </div>
    </div>
    <div class="row">
      <table v-if="excelData.length > 0" class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th v-for="(cell, index) in excelData[0]" :key="index">
              {{ cell }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in excelData.slice(1)"
            :key="rowIndex"
            class="border rounded smallList"
          >
            <td>
              <i class="fa fa-close" @click="removeFromUpload(row)"></i>
            </td>
            <td v-for="(cell, cellIndex) in row" :key="cellIndex">
              {{ cell }}
            </td>
            <td>
              <button
                class="btn btn-sm btn-success float-start"
                @click="addFromUpload(row)"
              >
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col">
        <PlayersList :masterdata="props.masterdata" :team="team"></PlayersList>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- <button
          class="btn btn-danger btn-sm mt-1 mx-auto"
          @click="deleteTeam(team)"
        >
          Delete Team
        </button> -->
        <i class="bi bi-trash float-end" @click="deleteTeam(team)"></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, ref } from "vue";
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { db } from "../../firebase/config";
const props = defineProps(["masterdata"]);
import PlayersList from "./PlayersList.vue";
import IconUpload from "./IconUpload.vue";
import TeamIcon from "./TeamIcon.vue";
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const team = ref("");
const player = ref("");
const error = ref("");
let XLSX = require("xlsx");
const excelData = ref([]);
const iconteam = ref("");
const getTeamName = (id) => {
  var name = "";
  name = props.masterdata.teams[id].name;
  return name;
};
const selectTeam = (id) => {
  if (team.value != id) {
    team.value = id;
  } else {
    team.value = "";
  }
  excelData.value = [];
};
const sorted_teams = computed(() => {
  const arr = [];
  for (const property in props.masterdata.teams) {
    //console.log(`${property}: ${object[property]}`);
    arr.push({
      id: property,
      name: props.masterdata.teams[property].name,
      icon: props.masterdata.teams[property].icon,
    });
  }
  let sorted_arr = sortByField(arr, "name");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array

  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const addPlayer = async () => {
  if (player.value) {
    //console.log(props.masterdata.id, team.value, player.value);
    const washingtonRef = doc(db, "masterevents", props.masterdata.id);
    await updateDoc(washingtonRef, {
      ["teams." + team.value + ".players." + nanoid()]: {
        player: player.value,
        photo: "",
      },
    });
  } else {
    error.value = "Player name cannot be blank";
  }
  player.value = "";
};
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet is the one you want to display
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet data to an array of objects
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      excelData.value = sheetData;
    };
    reader.readAsArrayBuffer(file);
  }
};
const removeFromUpload = (row) => {
  excelData.value = excelData.value.filter((item) => item != row);
  if (excelData.value.length == 1) {
    excelData.value = [];
    selectTeam(null);
  }
};
const addFromUpload = (row) => {
  //console.log("Adding =>", row[0]);
  player.value = row[0] + " (" + row[1] + ")";

  addPlayer();

  removeFromUpload(row);
};
const iconTeam = (id) => {
  if (iconteam.value != id) {
    iconteam.value = id;
  } else {
    iconteam.value = "";
  }
};
const deleteTeam = async (t) => {
  selectTeam(null);
  const washingtonRef = doc(db, "masterevents", props.masterdata.id);

  await updateDoc(washingtonRef, {
    ["teams." + t]: deleteField(),
  });
};
</script>
