<template>
  <div class="smalltxt">
    <!-- {{ bs.timenow }} {{ subevent.bidsobject }} -->
    <!-- {{ store.masterdata.predictivebids }} -->
    <div class="row mt-2" v-if="isThisSubeventParentToAnyEvent()">
      <div class="col-2 d-flex align-items-center justify-content-center"></div>
      <div class="col-10">
        <div class="row">
          <div class="col-5 text-center">
            <span
              ><TeamIcon
                :icon="subevent.logo_1"
                @click="logoSubmit(subevent, 'party_1')"
              ></TeamIcon
            ></span>
          </div>
          <div class="col-2 padding0">
            <ul class="list-group">
              <li class="list-group-item padding0 text-center border-0 bgg">
                <h6 style="margin: 0px">{{ subevent.name }}</h6>
              </li>
              <li class="list-group-item padding0 text-center border-0 bgg">
                <span
                  class="badge rounded-pill bg-danger mx-auto text-center padding2"
                  v-if="formatTime(subevent.lastbid - bs.timenow) != 'Closed'"
                >
                  {{ formatTime(subevent.lastbid - bs.timenow) }}</span
                >
                <span
                  class="badge rounded-pill mx-auto bg-secondary text-center padding2"
                  v-if="formatTime(subevent.lastbid - bs.timenow) == 'Closed'"
                  >Closed
                </span>
              </li>
            </ul>
          </div>
          <div class="col-5 text-center">
            <span
              ><TeamIcon
                :icon="subevent.logo_2"
                @click="logoSubmit(subevent, 'party_2')"
              ></TeamIcon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Transition>
      <div class="row" v-if="msg">
        <div class="col-2"></div>
        <div class="col-10 text-muted text-center">
          <h6 class="smallheading">{{ msg }}</h6>
        </div>
      </div>
    </Transition>
    <div class="row overflow-hidden">
      <div
        class="col-2 d-flex align-items-center justify-content-around padding0"
      >
        <ul class="list-group" v-if="!isThisSubeventParentToAnyEvent()">
          <li
            class="list-group-item padding0 text-center border-0 bgg"
            v-if="subevent.result"
          >
            <span v-if="subevent.result">
              <i
                class="bi bi-check2-all text-primary i14"
                v-if="checkResult(subevent.id, subevent.result)"
              ></i>
            </span>
          </li>
          <li class="list-group-item padding0 text-center border-0 bgg">
            {{ subevent.name }}
          </li>
          <li class="list-group-item padding0 text-center border-0 bgg">
            <span
              class="badge rounded-pill bg-danger mx-auto text-center padding2"
              v-if="formatTime(subevent.lastbid - bs.timenow) != 'Closed'"
            >
              {{ formatTime(subevent.lastbid - bs.timenow) }}</span
            >
            <span
              class="badge rounded-pill mx-auto bg-secondary text-center padding2"
              v-if="formatTime(subevent.lastbid - bs.timenow) == 'Closed'"
              >Closed
            </span>
          </li>
        </ul>
        <ul v-else class="list-group">
          <li
            class="list-group-item padding0 text-center border-0 bgg"
            v-if="subevent.result"
          >
            <span>
              <i
                class="bi bi-check2-all text-primary i14"
                v-if="checkResult(subevent.id, subevent.result)"
              ></i>
            </span>
          </li>
        </ul>
      </div>
      <div
        class="col-10 padding0 bgg"
        v-if="
          !checkIfBidSubmitted(subevent.id) &&
          formatTime(subevent.lastbid - bs.timenow) != 'Closed'
        "
      >
        <div class="row">
          <div class="col-5 text-center d-flex align-items-center">
            <div
              class="card flex-fill d-flex justify-content-around align-items-center overflow-hidden border-0"
              @click="setSubevent(subevent, 'party_1')"
            >
              <div class="card-body padding5">
                <div class="card-text text-center">
                  <ul class="list-group pressable-list">
                    <li
                      class="list-group-item text-center padding2 pastelred"
                      :class="getBtnClass('party_1')"
                      v-for="n in names_1"
                      :key="n"
                    >
                      {{ n }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 text-center d-flex align-items-center">
            <div
              class="card flex-fill d-flex justify-content-around align-items-center pressable-list"
              @click="setSubevent(subevent, 'Draw')"
              v-if="!isThisSubeventParentToAnyEvent()"
            >
              <div class="card-body padding5">
                <p class="card-text">A/S</p>
              </div>
            </div>
          </div>
          <div class="col-5 text-center d-flex align-items-center">
            <div
              class="card flex-fill d-flex justify-content-around align-items-center overflow-hidden border-0"
              @click="setSubevent(subevent, 'party_2')"
            >
              <div class="card-body padding5">
                <div class="card-text text-center">
                  <ul class="list-group pressable-list">
                    <li
                      class="list-group-item text-center padding2 pastelblue"
                      :class="getBtnClass('party_2')"
                      v-for="n in names_2"
                      :key="n"
                    >
                      {{ n }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition>
        <div
          class="col-10 padding0 bgg"
          v-if="
            checkIfBidSubmitted(subevent.id) &&
            formatTime(subevent.lastbid - bs.timenow) != 'Closed'
          "
        >
          <div class="row mt-1 mb-1">
            <div class="col-2">
              <!-- <TeamIcon :icon="subevent.logo_1"></TeamIcon> -->
            </div>

            <div
              class="col-8 rounded btn-shadow text-center d-flex align-items-center justify-content-around"
              :class="choice_string"
              @click="toggleTrash()"
            >
              <MedIcon
                :icon="subevent.logo_1"
                v-if="bid_party == 'party_1'"
              ></MedIcon>
              {{ cancelBidString() }}
              <MedIcon
                :icon="subevent.logo_2"
                v-if="bid_party == 'party_2'"
              ></MedIcon>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-around">
              <i
                class="bi bi-trash float-end text-danger"
                data-bs-dismiss="modal"
                v-if="trash"
                @click="cancelBid()"
              ></i>
            </div>
          </div>
        </div>
      </Transition>
      <div
        class="col-10 padding0 bgg"
        v-if="formatTime(subevent.lastbid - bs.timenow) == 'Closed'"
      >
        <ul class="list-group padding2">
          <li class="list-group-item padding2 border-0 bgg">
            <div class="row d-flex align-items-center">
              <div class="col-5 bgg">
                <div
                  class="card text-muted flex-fill d-flex justify-content-around align-items-center overflow-hidden border-0"
                  @click="toggleSubevent(subevent)"
                >
                  <div class="card-body padding5">
                    <div class="card-text text-center">
                      <ul class="list-group pressable-list">
                        <li
                          class="list-group-item text-center padding2"
                          :class="resultClass(subevent, 'party_1')"
                          v-for="n in names_1"
                          :key="n"
                        >
                          {{ n }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 bgg">
                <!-- Closed
                <i class="bi bi-arrow-down-circle"></i> -->

                <div
                  class="card flex-fill d-flex justify-content-around align-items-center pressable-list"
                  @click="toggleSubevent(subevent)"
                  v-if="formatTime(subevent.lastbid - bs.timenow) == 'Closed'"
                >
                  <div class="card-body padding2 rounded">
                    <div class="card-text text-center">
                      <i
                        class="bi bi-x-circle i14 text-danger"
                        v-if="subevent.id == se.subevent.id"
                      ></i>
                      <!-- <i
                        class="bi bi-arrow-down-circle i14"
                        v-if="subevent.id != se.subevent.id"
                      ></i> -->
                      <span v-if="subevent.id != se.subevent.id">Odds</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-5 bgg">
                <div
                  class="card text-muted flex-fill d-flex justify-content-around align-items-center overflow-hidden border-0"
                  @click="toggleSubevent(subevent)"
                >
                  <div class="card-body padding5">
                    <div class="card-text text-center">
                      <ul class="list-group pressable-list">
                        <li
                          class="list-group-item text-center padding2"
                          :class="resultClass(subevent, 'party_2')"
                          v-for="n in names_2"
                          :key="n"
                        >
                          {{ n }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            class="list-group-item padding2 border-0"
            v-if="subevent.id == se.subevent.id"
          >
            <Transition> <OutcomesComp></OutcomesComp></Transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useSubevents } from "@/store/subevents";
import { useBids } from "@/store/bids";
import { useStore } from "@/store/index";
import { ref, defineProps, watchEffect, computed, onMounted } from "vue";
import { storage, db, auth } from "../../firebase/config";
import MedIcon from "./MedIcon.vue";
import TeamIcon from "../user/TeamIcon.vue";
import OutcomesComp from "./OutcomesComp.vue";
import {
  doc,
  updateDoc,
  deleteField,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
const props = defineProps(["subevent"]);
const subevent = ref({});
const bid_party = ref("");
const se = useSubevents();
const bs = useBids();
const store = useStore();
const trash = ref(false);
const choice_string = ref("");
const msg = ref("");

function getRandomQuote() {
  const zenQuotes = [
    "Let go, time flows...submitting...",
    "Be still, all unfolds...submitting...",
    "In waiting, find calm...submitting...",
    "Now is enough...submitting...",
    "Calm brings peace...submitting...",
    "Wait with grace...submitting...",
    "Stillness brings joy...submitting...",
    "Breathe and wait...submitting...",
  ];

  const randomIndex = Math.floor(Math.random() * zenQuotes.length);
  return zenQuotes[randomIndex];
}
const logoSubmit = (subevent, party) => {
  //console.log("inside logo submit", party);
  if (!checkIfBidSubmitted(subevent.id)) {
    setSubevent(subevent, party);
  }
};
const toggleSubevent = (subevent) => {
  if (subevent.id != se.subevent.id) {
    se.setSubevent(subevent);
    se.clearTrash();
    setTimeStamp();
  } else {
    se.clearSubevent();
    se.clearTrash();
    setTimeStamp();
  }
};
const toggleTrash = () => {
  trash.value = !trash.value;
  setTimeStamp();
};
onMounted(() => {
  subevent.value = props.subevent;
});
watchEffect(() => {
  subevent.value = props.subevent;
});

var names_1 = computed(() => {
  var arr = subevent.value.party_1.split(", ");
  return arr;
});
var names_2 = computed(() => {
  var arr = subevent.value.party_2.split(", ");
  return arr;
});

const isThisSubeventParentToAnyEvent = () => {
  var isparent = false;
  se.subevents.forEach((s) => {
    if (s.parentevent == subevent.value.id) {
      isparent = true;
    }
  });
  return isparent;
};
const getIDofWin = () => {
  var winid = "";
  for (const property in subevent.value.oddsoptions) {
    //console.log(`${property}: ${object[property]}`);
    if (subevent.value.oddsoptions[property].name == "Win") {
      winid = property;
    }
  }
  return winid;
};
const getIDofDraw = () => {
  var drawid = "";
  for (const property in subevent.value.oddsoptions) {
    //console.log(`${property}: ${object[property]}`);
    if (subevent.value.oddsoptions[property].name == "Draw") {
      drawid = property;
    }
  }
  return drawid;
};
const setSubevent = (s, party) => {
  //console.log("check time stamp here");
  se.setSubevent(s);
  se.clearTrash();
  setTimeStamp();
  ////set bid party and bidid here ///////

  // se.bidFor(party);
  // if (party == "Draw") {
  //   se.bidID(getIDofDraw());
  // } else {
  //   se.bidID(getIDofWin());
  // }

  if (formatTime(subevent.value.lastbid - bs.timenow) != "Closed") {
    se.bidFor(party);
    if (party == "Draw") {
      se.bidID(getIDofDraw());
    } else {
      se.bidID(getIDofWin());
    }
    msg.value = "Submitting...";
    //msg.value = getRandomQuote();
    trash.value = false;
    setTimeout(() => {
      msg.value = msg.value + "done!";
      setTimeout(() => {
        msg.value = "";
        submitBid();
        if (store.masterdata.predictivebids) {
          checkForPredictiveBids(); //triggered only if set to true in masterdata
        }
      }, 1000);
    }, 1000);
  }
  /////////////submit bid here
};
const checkForPredictiveBids = () => {
  setTimeout(() => {
    //timeout is set for bid to be registered and bs.submittedbids to be updated

    //console.log(bs.submittedbids);
    var all_subevents_for_this_parent = fetchAllSubeventsForThisParent(
      subevent.value.parentevent
    );
    //console.log(all_subevents_for_this_parent); // to get number (n/2 +1)
    if (!isBidForParentEventAlreadySubmitted(subevent.value.parentevent)) {
      console.log(
        "PredictiveBidding -> parentevent:",
        subevent.value.parentevent,
        "subevent:",
        subevent.value.id
      );
      var party_1_bids = 0;
      var party_2_bids = 0;
      bs.submittedbids.forEach((sb) => {
        //console.log(sb);
        if (all_subevents_for_this_parent.includes(sb.subevent)) {
          if (sb.party == "party_1") {
            party_1_bids = party_1_bids + 1;
          }
          if (sb.party == "party_2") {
            party_2_bids = party_2_bids + 1;
          }
        }
      });
      //console.log("Bids for Party_1:", party_1_bids, " Party_2:", party_2_bids);
      var bid_limit = parseInt(all_subevents_for_this_parent.length / 2) + 1;
      //console.log(bid_limit);
      if (party_1_bids >= bid_limit) {
        //console.log("submit bid for team_1");
        submitParentBid(subevent.value.parentevent, "party_1");
      }
      if (party_2_bids >= bid_limit) {
        //console.log("submit bid for team_2");
        submitParentBid(subevent.value.parentevent, "party_2");
      }
    } else {
      console.log("parent bid already submitted...no-op");
    }
  }, 3000);
};
const submitParentBid = (parent, party) => {
  const bidObj = {
    party: party,
    bidid: getIDofWin(),
    timestamp: serverTimestamp(),
  };
  const parent_bidobject_id = getParentBidObjectID(parent);
  setTimeout(async () => {
    //settimeout put for getIDofWin() to resolve
    const washingtonRef = doc(db, "bids", parent_bidobject_id);
    await updateDoc(washingtonRef, {
      ["bids." + getFirst8Chars(auth.currentUser.uid)]: bidObj,
    });
    //updateBids(se.subevent.id, bidObj);
    bs.submittedBid({
      subevent: parent,
      bidid: bidObj.bidid,
      party: bidObj.party,
    });
  }, 1000);
};
const fetchAllSubeventsForThisParent = (parent) => {
  var subevents = [];
  se.subevents.forEach((s) => {
    if (s.parentevent == parent) {
      subevents.push(s.id);
    }
  });
  return subevents;
};
const getParentBidObjectID = (parent) => {
  var bidobjid = "";
  se.subevents.forEach((s) => {
    if (s.id == parent) {
      bidobjid = s.bidsobject;
    }
  });
  return bidobjid;
};
const isBidForParentEventAlreadySubmitted = (parent) => {
  var parent_bid = false;
  bs.submittedbids.forEach((sb) => {
    //console.log(sb);
    if (sb.subevent == parent) {
      parent_bid = true;
    }
  });
  return parent_bid;
};
const checkIfBidSubmitted = (subevent) => {
  //return bs.subevents.includes(subevent);
  var exists = false;
  bs.submittedbids.forEach((b) => {
    if (b.subevent == subevent) {
      exists = true;
    }
  });
  return exists;
};

const submitBid = async () => {
  //console.log("bid submitted");
  const bidObj = {
    party: se.bidfor,
    bidid: se.bidid,
    timestamp: serverTimestamp(),
  };

  if (subevent.value.neutraloptions.includes(se.bidid)) {
    bidObj.party = "NeutralBid";
  }
  //console.log(bidObj);
  const washingtonRef = doc(db, "bids", subevent.value.bidsobject);
  await updateDoc(washingtonRef, {
    ["bids." + getFirst8Chars(auth.currentUser.uid)]: bidObj,
  });
  //updateBids(se.subevent.id, bidObj);
  bs.submittedBid({
    subevent: subevent.value.id,
    bidid: bidObj.bidid,
    party: bidObj.party,
  });
};
const cancelBidString = () => {
  var party = "";
  var bidid = "";
  var str = "";
  bs.submittedbids.forEach((b) => {
    if (b.subevent == subevent.value.id) {
      party = b.party;
      bidid = b.bidid;
    }
  });
  bid_party.value = party; //this is for showing icon in selected choice
  if (party == "party_1") {
    choice_string.value = "pastelred";
  } else if (party == "party_2") {
    choice_string.value = "pastelblue";
  } else {
    choice_string.value = "coral";
  }
  if (party) {
    str = str + subevent.value[party];
  }
  if (bidid) {
    str = str + " to " + getBidName(bidid);
  }
  if (subevent.value.neutraloptions.includes(bidid)) {
    str = getBidName(bidid);
    str = "A/S";
  }
  //trash.value = false;
  //se.toggleTrash(); //this is done to show trash icon when div is shown
  return str;
};
const getBidName = (id) => {
  var n = { name: "" };
  n = subevent.value.oddsoptions[id];
  return n.name;
};
function getFirst8Chars(str) {
  return str.substring(0, 8);
}
function formatTime(seconds) {
  if (!subevent.value.acceptbids) {
    return "Closed";
  }
  if (seconds < 0) {
    return "Closed";
  }
  let hours = Math.floor(seconds / 3600);

  // If hours exceed 3, return "3:00+"
  if (hours > 3) {
    return "3:00+";
  }

  // Calculate the number of minutes remaining
  let minutes = Math.floor((seconds % 3600) / 60);

  // Add leading zero to minutes if needed
  let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Return the formatted time in HH:mm
  return `${hours}:${formattedMinutes}`;
  // if (seconds < 60) {
  //   return "0:01";
  // }
  // const minutes = Math.floor(seconds / 60);
  // const hours = Math.floor(minutes / 60);
  // const remainingMinutes = minutes % 60;

  // if (hours > 3) {
  //   return "3:00"; ///this is added to prevent the extra long hours error on first login
  // }

  // if (hours > 0) {
  //   return `${hours}${hours !== 1 ? "" : ""}:${remainingMinutes}${
  //     remainingMinutes !== 1 ? "" : ""
  //   } `;
  // } else {
  //   return `0:${minutes}${minutes !== 1 ? "" : ""} `;
  // }
}
const cancelBid = async () => {
  const cityRef = doc(db, "bids", subevent.value.bidsobject);

  await updateDoc(cityRef, {
    ["bids." + getFirst8Chars(auth.currentUser.uid)]: deleteField(),
  });
  bs.removeBid(subevent.value.id, auth.currentUser.uid);
  setTimeStamp();
};
const setTimeStamp = async () => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(docRef, {
    timenow: serverTimestamp(),
  });
};
const checkResult = (subevent, result) => {
  var res = false;
  bs.submittedbids.forEach((b) => {
    if (b.subevent == subevent) {
      if (b.bidid == result.bidid && b.party == result.party) {
        res = true;
      }
    }
  });
  return res;
};
const resultClass = (subevent, party) => {
  var rclass = "";
  //console.log(subevent.id);
  se.subevents.forEach((s) => {
    if (s.id == subevent.id && s.result) {
      if (s.result.party == party && party == "party_1") {
        rclass = "pastelred";
      }
      if (s.result.party == party && party == "party_2") {
        rclass = "pastelblue";
      }
      if (s.result.party == "NeutralBid") {
        rclass = "coral";
      }
    }
  });
  return rclass;
};
const getBtnClass = (party) => {
  var cl = "";
  if (isThisSubeventParentToAnyEvent()) {
    if (party == "party_1") {
      cl = "pulsating-btn-red";
    }
    if (party == "party_2") {
      cl = "pulsating-btn-blue";
    }
  }
  return cl;
};
</script>
<style scoped>
.smallheading {
  font-size: 14px;
}
.bi-trash {
  font-size: 14px;
}
.i14 {
  font-size: 14px;
}
.coral {
  /* background-color: coral; */
  background-color: #cb99c9;
  padding: 3px;
}
.smalltxt {
  font-size: 11px;
}
.card {
  flex: 1 1 auto;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-text {
  flex-grow: 1;
}
.padding5 {
  padding: 5px;
}
.padding2 {
  padding: 2px;
}
.padding0 {
  padding: 0px;
}
.stacked-container {
  position: relative;
}

.top-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.bottom-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.transparent-item {
  background-color: transparent;
  border: none;
  text-shadow: 1px 1px 2px white; /* Makes text stand out */
  color: black; /* Ensures text contrasts */
  font-weight: bold;
  font-size: 14px;
}
.pastelyellow {
  background-color: #fefdc0;
}
.pastelred {
  background-color: #ffa29a;
}
.pastelblue {
  background-color: #9acdff;
}
.btn-shadow {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3); /* Light shadow */
  transition: box-shadow 0.3s ease;
}
.pressable-list {
  list-style-type: none;
  padding: 1px;
  margin: 0px;
  background-color: white;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3); /* Light shadow */
  transition: box-shadow 0.3s ease;
}
.bgg {
  background: linear-gradient(to bottom right, #fcfcfc, #fafafa);
}
.card {
  background: linear-gradient(to bottom right, #fcfcfc, #fafafa);
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.pulsating-btn-blue {
  position: relative;
  animation: pulsateblue 1s infinite;
}
@keyframes pulsateblue {
  0% {
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 7px rgba(0, 123, 255, 1);
  }
  100% {
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
}

.pulsating-btn-red {
  position: relative;
  animation: pulsatered 1s infinite;
}
@keyframes pulsatered {
  0% {
    box-shadow: 0 0 3px rgba(255, 69, 0, 0.5); /* Equivalent red shade */
  }
  50% {
    box-shadow: 0 0 7px rgba(255, 69, 0, 1); /* Equivalent red shade */
  }
  100% {
    box-shadow: 0 0 3px rgba(255, 69, 0, 0.5); /* Equivalent red shade */
  }
}
</style>
