<template>
  <span class="bg-warning" v-if="error">{{ error }}</span>
  <input
    type="text"
    class="form-control"
    v-model.trim="name"
    placeholder="New team..."
    @keyup="clearError()"
    @keyup.enter="submit()"
  />
</template>
<script setup>
import { ref } from "vue";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useStore } from "../../store/index";
const { customAlphabet } = require("nanoid");
const alphabet = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const name = ref("");
const error = ref("");
const store = useStore();
const clearError = () => {
  error.value = "";
};
const submit = async () => {
  if (name.value) {
    const washingtonRef = doc(db, "masterevents", store.masterevent);

    await updateDoc(washingtonRef, {
      ["teams." + nanoid()]: { name: name.value, icon: null },
    });
  } else {
    error.value = "Team name cannot be blank";
  }

  //console.log(name.value, nanoid());
  name.value = "";
};
</script>
@
