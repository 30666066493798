<template>
  <div class="row" v-if="!store.masterevent">
    <MasterEventList></MasterEventList>
  </div>
  <div v-if="store.masterevent">
    <div class="row">
      <div class="col">
        <span class="text-center">Set results for {{ store.mastername }}</span>
        <i class="bi bi-x-lg float-end" @click="store.clearMasterEvent()"></i>
      </div>
    </div>
    <div class="row">
      <!-- {{ all_subevents.length }} -->
      <MasterHome :allsubevents="all_subevents"></MasterHome>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { watchEffect, ref, onBeforeUnmount } from "vue";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import MasterEventList from "../user/MastereventList.vue";
import MasterHome from "./MasterHome.vue";
const store = useStore();
const all_subevents = ref([]);
var unsubscribe = () => {};
watchEffect(async () => {
  const q = query(
    collection(db, "subevents"),
    where("masterevent", "==", store.masterevent)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    all_subevents.value = cities;
    //console.log("Current cities in CA: ", cities.join(", "));
  });
  if (store.masterevent) {
    const docRef = doc(db, "masterevents", store.masterevent);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      store.setMasterData({ id: store.masterevent, ...docSnap.data() });
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }
});
onBeforeUnmount(() => {
  unsubscribe();
});
</script>
