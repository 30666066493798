<template>
  <div class="d-flex justify-content-around mt-1">
    <div v-for="master in masterevents" :key="master.id">
      <button
        class="btn btn-sm btn-primary"
        @click="selectMaster(master.id, master.name, master)"
      >
        {{ master.name }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { ref, onMounted, onBeforeUnmount } from "vue";
const store = useStore();
const masterevents = ref([]);
var unsubscribe = () => {};
onBeforeUnmount(() => {
  unsubscribe();
  //console.log("unsubscribing event list...");
});
onMounted(() => {
  const q = query(
    collection(db, "masterevents"),
    where("admin", "==", store.userid)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    //console.log("Current cities in CA: ", cities.join(", "));
    masterevents.value = cities;
  });
});
const selectMaster = (id, name, master) => {
  ///store.clearParentsArray();
  //console.log("selecting Master");
  store.setMasterEvent(id, name);
  store.setMasterData(master);
};
</script>
