<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-around rounded"
  >
    <i
      class="bi bi-gear"
      @click="togglesettings = !togglesettings"
      v-if="!togglesettings"
    ></i>
    <i
      class="bi bi-x-lg glow-danger"
      @click="togglesettings = !togglesettings"
      v-if="togglesettings"
    ></i>
    <!-- <i class="bi bi-info-circle" @click="toInfo()"></i> -->
    <button
      class="btn btn-sm btn-danger"
      v-if="store.mastername"
      @click="clearMasterEvent()"
    >
      <i class="bi bi-x-circle float-end mx-1"></i>
    </button>

    <button
      class="btn btn-sm btn-success"
      v-if="store.mastername"
      @click="toHome()"
    >
      <i class="bi bi-house"></i>
    </button>

    <button
      class="btn btn-sm btn-primary"
      v-if="store.mastername"
      @click="toMyBids()"
    >
      <i class="bi bi-card-checklist"></i>
    </button>

    <button
      class="btn btn-sm golden"
      @click="toMyLeaderboard()"
      v-if="store.mastername"
    >
      <i class="bi bi-trophy"></i>
    </button>
    <i
      class="bi bi-arrow-clockwise glow-success"
      @click="refreshApp()"
      v-if="hash != user_hash"
    ></i>

    <i class="bi bi-power" @click="logout"></i>
  </nav>
  <div class="container" v-if="hash != user_hash">
    <div class="row">
      <div class="col bg-warning text-center rounded">
        Downloading new version...Please click
        <i class="bi bi-arrow-clockwise"></i>
      </div>
    </div>
  </div>
  <div class="container" v-if="togglesettings">
    <UserSettings></UserSettings>
  </div>
</template>
<script setup>
// import { signOut } from "firebase/auth";
// import { auth } from "../../firebase/config";
import { useRouter } from "vue-router";
import { useStore } from "../../store/index";
import { useSubevents } from "../../store/subevents";
import { useBids } from "../../store/bids";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { ref, watchEffect, onMounted, onBeforeUnmount, computed } from "vue";
import UserSettings from "../../components/user/UserSettings.vue";
const dayjs = require("dayjs");
const store = useStore();
const se = useSubevents();
const bs = useBids();
const router = useRouter();
const masterevent = ref("");
const togglesettings = ref(false);
import firebaseConfig from "../../firebase/firebaseConfig";
import firebase from "firebase/compat/app";
firebase.initializeApp(firebaseConfig);
import "firebaseui/dist/firebaseui.css";
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();
var unsubscribe = () => {};
var unsub = () => {};
const hash = ref("");
const user_hash = ref("");
//nanoid() //=> "GDWP"
var build_subscribe = () => {};
var user_subscribe = () => {};
onMounted(() => {
  build_subscribe = onSnapshot(
    doc(db, "buildhash", "lNZRfY4I36kZ3Qy7W6s3"),
    (docy) => {
      hash.value = docy.data().hash;
    }
  );
  user_subscribe = onSnapshot(
    doc(db, "users", auth.currentUser.uid),
    (docy) => {
      //console.log("UserNav here =>", docy.data());
      if (docy.data() != undefined) {
        user_hash.value = docy.data().buildhash;
      }
    }
    //   if (docy.data().buildhash !== undefined)
    //     user_hash.value = docy.data().buildhash;
    // }
  );
});
onBeforeUnmount(() => {
  build_subscribe();
  user_subscribe();
});
const logout = () => {
  //console.log(auth.currentUser.uid);
  bs.clearBids();
  bs.clearPresentTime();
  store.clearMasterEvent();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      //user.value = null;
      //isSignedIn.value = false;
      router.push("/");
      //console.log("Signed out");
      //ui.start("#firebaseui-auth-container", uiConfig);
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
};
watchEffect(() => {
  masterevent.value = store.masterevent;
  if (masterevent.value) {
    // console.log("fetch data for MasterEvent =>", masterevent.value);
    const q = query(
      collection(db, "subevents"),
      where("masterevent", "==", masterevent.value)
    );
    unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((docy) => {
        if (docy.data().active) {
          //add only active events
          cities.push({ id: docy.id, ...docy.data() });
        }
      });

      se.setSubevents(cities);
      setLatestDate();
    });
    unsub = onSnapshot(doc(db, "masterevents", masterevent.value), (docy) => {
      //console.log("Current data: ", doc.data());
      store.setMasterData({ id: docy.id, ...docy.data() });
    });
  } else {
    //console.log("remove listeners for this masterEvent");
    unsubscribe();
    unsub();
    se.clearSubevents();
    store.clearMasterEvent();
  }
  if (hash.value != "" && user_hash.value != "") {
    // automatic refresh when new hash is detected
    if (hash.value != user_hash.value) {
      setTimeout(() => {
        refreshApp();
      }, 3000);
    }
  }
});
const clearMasterEvent = () => {
  store.clearMasterEvent();
  bs.clearBids();
  toHome();
};
const toMyBids = () => {
  router.push("/mybids");
};
const toHome = () => {
  router.push("/home");
};
const toMyLeaderboard = () => {
  router.push("/leaderboard");
};
const toInfo = () => {
  router.push("/info");
};
const refreshApp = async () => {
  const washingtonRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(washingtonRef, {
    buildhash: hash.value,
  });
  setTimeout(() => {
    window.location.reload(true);
  }, 1000);
};
const setLatestDate = () => {
  store.addEventDate(newestEvent.value);
};
const newestEvent = computed(() => {
  var latest_event = "";
  if (se.subevents.length) {
    latest_event = se.subevents.reduce((latest, event) => {
      return dayjs(event.eventdate).isAfter(dayjs(latest.eventdate))
        ? event.eventdate
        : latest;
    });
  }
  return latest_event;
});
</script>
<style scoped>
.golden {
  background-color: gold;
}
.glow-danger {
  color: var(--bs-danger);
  filter: drop-shadow(0 0 10px var(--bs-danger));
}
.glow-success {
  color: var(--bs-success);
  filter: drop-shadow(0 0 10px var(--bs-success));
}
</style>
