<template>
  <div v-if="fileUrl" class="image-container">
    <img :src="fileUrl" class="round-image" @click="toggle = !toggle" />
  </div>
  <i class="bi bi-trash float-end" v-if="toggle" @click="deleteIcon()"></i>
</template>
<script setup>
import { defineProps, onMounted, ref } from "vue";
import { storage, db } from "../../firebase/config";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { useStore } from "../../store/index";
const store = useStore();
const props = defineProps(["icon"]);
const toggle = ref(false);
const fileUrl = ref("");
onMounted(() => {
  getFileUrl();
});
const getFileUrl = async () => {
  try {
    //const fileRef = storageRef(storage, "images/" + props.icon);
    const fileRef = storageRef(storage, "images/" + store.masterdata.eventicon);
    const url = await getDownloadURL(fileRef);
    fileUrl.value = url;
  } catch (error) {
    console.error("Error retrieving file URL:", error);
  }
};
const deleteIcon = async () => {
  //console.log(props.icon, props.team, store.masterevent);
  const washingtonRef = doc(db, "masterevents", store.masterevent);

  await updateDoc(washingtonRef, {
    eventicon: null,
  });
  toggle.value = false;
};
</script>
<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.round-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}
</style>
