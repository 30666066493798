<template>
  <div class="container">
    <AdminNav v-if="store.isadmin"></AdminNav>
    <DeclareResults></DeclareResults>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import AdminNav from "../../components/navbars/AdminNav.vue";
import DeclareResults from "../../components/results/DeclareResults.vue";

const store = useStore();
</script>
