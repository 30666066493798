<template>
  <div class="row">
    <div class="col">
      <span
        class="badge rounded-pill bg-danger"
        @click="togglePositionMovement()"
        >Reset Position Movement</span
      >
    </div>
    <div class="col" v-if="positionmovement">
      <PositionMovement></PositionMovement>
    </div>
  </div>
  <ul class="list-group">
    <li
      class="list-group-item smalllist"
      v-for="m in subeventsForThisMaster"
      :key="m.id"
    >
      <div class="row mt-2">
        <div class="col">
          <span class="bg-warning extrapadding mt2">{{ m.name }}</span>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="list-group-item tinytxt">{{ m.id }}</li>
            <li class="list-group-item tinytxt">{{ m.bidsobject }}</li>
          </ul>
        </div>
        <div class="col">
          <BidCount :subevent="m.id"></BidCount>
        </div>
        <div class="col-2">
          <span
            class="border rounded extrapadding mt-2"
            :class="getPartyClass('party_1', m.id)"
            @click="selectParty('party_1', m.id)"
          >
            {{ m.party_1 }}
          </span>
        </div>
        <div class="col-2">
          <span
            class="border rounded extrapadding mt-2"
            :class="getPartyClass('party_2', m.id)"
            @click="selectParty('party_2', m.id)"
          >
            {{ m.party_2 }}
          </span>
        </div>
        <div class="col d-flex justify-content-around">
          <span
            v-for="o in sorted_odds(m.oddsoptions)"
            class="border rounded"
            @click="selectOdd(o.id, m.id)"
            :key="o.id"
            :class="getOddClass(o.id, m.id)"
          >
            {{ o.name }}
            <i class="fa fa-arrows-h" v-if="isNeutralOption(m.id, o.id)"></i>
          </span>
        </div>
        <div class="col">
          <i
            class="fa fa-question"
            style="font-size: 18px; color: red"
            v-if="!m.result"
          ></i>
          <span v-if="m.result" @click="selectResultToDelete(m.id)">
            {{ resultDisplayString(m.id, m.result, m.oddsoptions) }}
          </span>
          <i
            class="bi bi-trash float-end"
            v-if="deleteresultfor == m.id"
            @click="clearResult(m.id)"
          ></i>
        </div>
        <div class="col">
          <button
            class="btn btn-sm btn-success"
            v-if="showDeclareBtn(m.id)"
            @click="declareResult(m.id)"
          >
            Declare!
          </button>
        </div>
      </div>
      <div class="mt-1">
        <SubeventList
          :allsubevents="props.allsubevents"
          :parentevent="m.id"
          :sortby="m.subeventsort"
        ></SubeventList>
      </div>
    </li>
  </ul>
  DeclaredResults: {{ declaredsubevents.length }} - {{ leaders.length }}
  <ul class="list-group">
    <li class="list-group-item" v-for="l in leaders" :key="l.userid">
      <pre>{{ l }}</pre>
    </li>
  </ul>
</template>
<script setup>
import { ref, defineProps, computed } from "vue";
import { useStore } from "../../store/index";
import { useSubevents } from "../../store/subevents";
import BidCount from "../events/BidCount.vue";
import { db } from "../../firebase/config";
import {
  doc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import SubeventList from "./SubeventList.vue";
import PositionMovement from "./PositionMovement.vue";
const props = defineProps(["allsubevents"]);
const store = useStore();
const se = useSubevents();
const party = ref("");
const party_master = ref("");
const bidid = ref("");
const bid_master = ref("");
const deleteresultfor = ref("");
const positionmovement = ref(false);
const togglePositionMovement = () => {
  positionmovement.value = !positionmovement.value;
};
const declaredsubevents = computed(() => {
  const arr = [];
  se.subevents.forEach((s) => {
    if (s.result != null) {
      arr.push(s.id);
    }
  });
  //populateLeaderBoard();
  return arr;
});
const selectParty = (p, master) => {
  (bidid.value = ""), (bid_master.value = "");
  if (master == party_master.value && p == party.value) {
    party_master.value = "";
    party.value = "";
  } else {
    party_master.value = master;
    party.value = p;
  }
};
const subeventsForThisMaster = computed(() => {
  const arr = [];
  props.allsubevents.forEach((s) => {
    if (s.parentevent == store.masterevent) {
      arr.push(s);
    }
  });
  var sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
const selectOdd = (odd, master) => {
  if (master == bid_master.value && odd == bidid.value) {
    bid_master.value = "";
    bidid.value = "";
  } else {
    bid_master.value = master;
    bidid.value = odd;
  }
};
function sortByField(arr, field) {
  if (store.masterdata.subeventsort == "asc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] > b[field]) {
        return 1;
      } else if (a[field] < b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  if (store.masterdata.subeventsort == "desc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
const sorted_odds = (odds) => {
  var arr = [];
  for (const property in odds) {
    arr.push({
      id: property,
      ...odds[property],
    });
  }
  let sorted_arr = sortByField_odds(arr, "sno");
  return sorted_arr;
};
function sortByField_odds(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const getPartyClass = (p, master) => {
  var cl = "";
  if (party_master.value == master && p == party.value) {
    cl = "coral";
  }
  return cl;
};
const getOddClass = (id, master) => {
  var cl = "";
  if (bidid.value == id && bid_master.value == master) {
    if (party_master.value == bid_master.value) {
      cl = "lightgreen";
    }
  }

  return cl;
};
const showDeclareBtn = (id) => {
  var ret = false;
  if (bidid.value && bid_master.value == id) {
    if (bid_master.value == party_master.value) {
      ret = true;
    }
  }

  return ret;
};
const declareResult = async (master) => {
  if (master == party_master.value) {
    //console.log("declare results for ", master);
    const resultObj = {
      party: getResultString(party_master.value),
      bidid: bidid.value,
    };
    //console.log(resultObj);
    const washingtonRef = doc(db, "subevents", master);
    // Set the "capital" field of the city 'DC'
    await updateDoc(washingtonRef, {
      result: resultObj,
    });
    //////updated bids collection too
    //     const bidRef = doc(db, "bids", "DC");
    const q = query(collection(db, "bids"), where("subevent", "==", master));
    const querySnapshot = await getDocs(q);
    const bid_obj_id = querySnapshot.docs[0].id;
    //console.log("BidObject MASTEREVENT=>", bid_obj_id);
    const bidRef = doc(db, "bids", bid_obj_id);
    // // Set the "capital" field of the city 'DC'
    await updateDoc(bidRef, {
      resultdeclared: true,
    });
    /////////////////////////////
    setTimeout(() => {
      calculateLeaderboard();
    }, 2000);
    setTimeout(() => {
      updateDatabase();
    }, 7000);
    party.value = "";
    party_master.value = "";
    bidid.value = "";
    bid_master.value = "";
  }
};
const isNeutralOption = (event, bidid) => {
  var neutral = false;
  props.allsubevents.forEach((s) => {
    if (s.id == event) {
      if (s.neutraloptions.includes(bidid)) neutral = true;
    }
  });
  return neutral;
};
const getResultString = (event) => {
  var str = "";
  if (isNeutralOption(event, bidid.value)) {
    str = "NeutralBid";
  } else {
    str = party.value;
  }
  return str;
};
const getBidName = (bid, options) => {
  var str = "";
  str = options[bid].name;
  return str;
};
const getPartyName = (eventid, party) => {
  var name = "";
  props.allsubevents.forEach((s) => {
    if (s.id == eventid) {
      name = s[party];
    }
  });
  return name;
};
const resultDisplayString = (eventid, result, options) => {
  var str = "";

  if (!getPartyName(eventid, result.party)) {
    str = getBidName(result.bidid, options);
  } else {
    str =
      getPartyName(eventid, result.party) +
      " to " +
      getBidName(result.bidid, options);
  }
  return str;
};
const selectResultToDelete = (event) => {
  if (deleteresultfor.value != event) {
    deleteresultfor.value = event;
  } else {
    deleteresultfor.value = "";
  }
};
const clearResult = async (master) => {
  const washingtonRef = doc(db, "subevents", master);
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    result: null,
  });
  ///////upadating bids collection/////////////////////
  const q = query(collection(db, "bids"), where("subevent", "==", master));
  const querySnapshot = await getDocs(q);
  const bid_obj_id = querySnapshot.docs[0].id;
  //console.log("BidObject MASTER EVENT=>", bid_obj_id);
  const bidRef = doc(db, "bids", bid_obj_id);
  // // Set the "capital" field of the city 'DC'
  await updateDoc(bidRef, {
    resultdeclared: false,
  });
  ///////////////////////
  setTimeout(() => {
    calculateLeaderboard();
  }, 2000);
  setTimeout(() => {
    updateDatabase();
  }, 7000);
  deleteresultfor.value = "";
  party_master.value = "";
  bid_master.value = "";
};

const leaders = ref([]);
const calculateLeaderboard = () => {
  leaders.value = []; //initilize leaders

  //console.log("DeclaredResults=>", declaredsubevents.value);
  declaredsubevents.value.forEach(async (ds) => {
    //get bid coll objects only for subevents with declared results
    const q = query(collection(db, "bids"), where("subevent", "==", ds));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((bidCollObj) => {
      //console.log("Calculating for =>", bidCollObj.id);
      for (const property in bidCollObj.data().bids) {
        updateLeaders({
          userid: property,
          subevent: bidCollObj.data().subevent,
          party: bidCollObj.data().bids[property].party,
          bidid: bidCollObj.data().bids[property].bidid,
          win: checkWin(
            bidCollObj.data().subevent,
            bidCollObj.data().bids[property].party,
            bidCollObj.data().bids[property].bidid
          ),
        });
      }
    });
  });
};
const checkWin = (subevent, party, bidid) => {
  var win = false;
  se.subevents.forEach((s) => {
    if (s.id == subevent && s.result) {
      if (s.result.party == party && s.result.bidid == bidid) {
        win = true;
      }
    }
  });
  return win;
};
const updateLeaders = async (obj) => {
  //console.log(obj);
  //leaders.value = [];
  var exists = false;
  leaders.value.forEach((e) => {
    //to check if element exists
    if (e.u == obj.userid) exists = true;
  });
  if (!exists) {
    leaders.value.push({
      u: obj.userid, //u= userid
      w: 0, //w = wins
      p: 0, //p = played
      s: 0, //s = strikerate
    });
  }
  leaders.value.forEach((element) => {
    if (element.u == obj.userid) {
      element.p = element.p + 1;
      if (obj.win) {
        element.w = element.w + 1;
      }
    }
  });
  leaders.value.forEach((element) => {
    element.s = strikeRate(element.w, element.p);
  });
  leaders.value.sort((a, b) => {
    if (a.s === b.s) {
      return b.p - a.p;
    }
    return b.s - a.s;
  });
};
const updateDatabase = async () => {
  const washingtonRef = doc(db, "leaderboards", store.masterevent);

  // Set the "capital" field of the city 'DC'
  //console.log("writing leaders.... ", Date.now());
  await updateDoc(washingtonRef, {
    leaderboard: leaders.value,
  });
};
const strikeRate = (wins, played) => {
  var rate = 0;
  rate = ((wins / played) * 100).toFixed(2);
  if (isNaN(rate)) {
    rate = 0;
  }
  return rate;
};
</script>

<style scoped>
.coral {
  background-color: coral;
}
.lightgreen {
  background-color: lightgreen;
}

.smalllist {
  padding: 2px;
  font-size: 12px;
}
.extrapadding {
  padding: 5px;
  font-size: 18px;
}
.tinytxt {
  font-size: 10px;
  padding: 2px;
}
</style>
