<template>
  <ul class="list-group">
    <li
      class="list-group-item smalllist"
      v-for="s in sorted_subevents"
      :key="s.id"
    >
      <button
        class="btn btn-sm btn-secondary"
        v-if="!store.subeventsforprize.includes(s.id)"
        @click="addToList(s.id)"
      >
        Add
      </button>
      <button
        class="btn btn-sm btn-danger"
        @click="removeFromList(s.id)"
        v-if="store.subeventsforprize.includes(s.id)"
      >
        Remove
      </button>
      {{ s.name }} - {{ s.eventdate }} - {{ s.party_1 }} , {{ s.party_2 }}

      <SubeventList :parentevent="s.id"></SubeventList>
    </li>
  </ul>
</template>
<script setup>
import SubeventList from "./SubeventList.vue";
import { useStore } from "@/store/index";
const store = useStore();
import { defineProps, ref, onMounted, computed, watchEffect } from "vue";
const props = defineProps(["parentevent"]);
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
const subevents = ref([]);

onMounted(async () => {
  const q = query(
    collection(db, "subevents"),
    where("parentevent", "==", props.parentevent)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((docy) => {
    subevents.value.push({ id: docy.id, ...docy.data() });
  });
});
const sorted_subevents = computed(() => {
  const arr = [];
  subevents.value.forEach((s) => {
    arr.push(s);
  });
  var sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const addToList = (id) => {
  store.addForPrize(id);
};
const removeFromList = (id) => {
  //store.removeFromPrize(id);
  store.resetPrizeArr();
};
</script>
<style scoped>
.smalllist {
  font-size: 10px;
}
.btn-sm {
  font-size: 10px;
  padding: 3px;
}
</style>
