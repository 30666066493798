<template>
  <ul class="list-group">
    <li class="list-group-item" v-for="(p, index) in players_list" :key="p.id">
      <i
        class="bi bi-trash float-start mx-2"
        v-if="player == p.id"
        @click="deletePlayer(p.id)"
      ></i>
      <span @click="selectPlayer(p.id)"> {{ index + 1 }}. {{ p.name }} </span>
      <i
        class="bi bi-pencil float-end padding5"
        @click="toggleEdit(p.id, p.name)"
      ></i>
      <i class="bi bi-camera float-end padding5"></i>
      <input
        type="text"
        class="form-control"
        v-if="editid == p.id"
        v-model.trim="edit_player"
        @keyup.enter="editPlayer(p.id)"
      />
    </li>
  </ul>
</template>
<script setup>
import { defineProps, computed, ref } from "vue";
import { db } from "../../firebase/config";
import { doc, updateDoc, deleteField } from "firebase/firestore";
const props = defineProps(["masterdata", "team"]);
const editid = ref("");
const player = ref("");
const edit_player = ref("");
//const playerslist = ref([]);
const players_list = computed(() => {
  const arr = [];
  for (const property in props.masterdata.teams[props.team].players) {
    //console.log(`${property}: ${object[property]}`);
    arr.push({
      id: property,
      name: props.masterdata.teams[props.team].players[property].player,
      photo: props.masterdata.teams[props.team].players[property].photo,
    });
  }
  var sorted_arr = sortByField(arr, "name");
  return sorted_arr;
});
function sortByField(arr, field) {
  // Use slice() to create a new array and avoid mutating the original array
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] > b[field]) {
      return 1;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const selectPlayer = (id) => {
  if (player.value != id) {
    player.value = id;
  } else {
    player.value = "";
  }
};
const deletePlayer = async () => {
  console.log(props.team, player.value, props.masterdata.id);
  const washingtonRef = doc(db, "masterevents", props.masterdata.id);

  await updateDoc(washingtonRef, {
    ["teams." + props.team + ".players." + player.value]: deleteField(),
  });
  selectPlayer(null);
};
const toggleEdit = (id, player) => {
  if (editid.value == id) {
    editid.value = "";
    edit_player.value = "";
  } else {
    editid.value = id;
    edit_player.value = player;
  }
};
const editPlayer = async (id) => {
  console.log(id, props.team);
  const washingtonRef = doc(db, "masterevents", props.masterdata.id);

  await updateDoc(washingtonRef, {
    ["teams." + props.team + ".players." + id + ".player"]: edit_player.value,
  });
  editid.value = "";
  edit_player.value = "";
  selectPlayer(null);
};
</script>
<style scoped>
.padding5 {
  padding: 5px;
}
</style>
